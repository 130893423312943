import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
    Paper, Tabs, Tab, TextField, Button
} from "@material-ui/core";
import {Link, useHistory, useParams} from "react-router-dom";

export const TranslationsNewLang = () => {
    const [value, setValue] = useState(1);
    const [translation, setTranslation] = useState();
    const history = useHistory();
    const {id} = useParams()


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addNewLang = async (e) => {
        try {
            e.preventDefault();
            await client.addTranslationLang({
                locale: e.target.locale.value,
                name: e.target.name.value
            });
            notification.success("New language added");
            history.push("/translations")
        } catch (e) {
            notification.warning(e.message || e.error);
        }
    }

    return (
        <>
            <Paper square>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                >
                    <Tab label="All" component={Link} to={'/translations'}/>
                    <Tab label="Add new lang" component={Link} to={'/translations/addNewLang'}/>
                    <Tab label="Add new record" component={Link} to={'/translations/addNewRecord'}/>
                </Tabs>
            </Paper>

            <Paper square className="simpleFormPaper">
                <form onSubmit={addNewLang}>
                    <TextField
                        id="locale"
                        name="locale"
                        type="text"
                        label="Locale"
                        placeholder="Locale"
                        autoComplete="off"
                        fullWidth={true}
                        required={true}
                    />
                    <TextField
                        id="name"
                        name="name"
                        type="text"
                        label="Language name"
                        placeholder="Language name"
                        autoComplete="off"
                        fullWidth={true}
                        required={true}
                    />
                    <div className="buttonContainer">
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            type="submit"
                        >
                            Add
                        </Button>
                    </div>
                </form>
            </Paper>
        </>
    )
}
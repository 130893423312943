import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TableContainer, Button, Tabs, Tab, TextField, FormControl, Select, MenuItem, FormHelperText, Modal
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";
import EditIcon from '@material-ui/icons/Edit';

export const Translations = () => {
    const [value, setValue] = useState(0);
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotal] = useState(1);
    const [translations, setTranslations] = useState();
    const [langs, setLangs] = useState([]);
    const [filterLocale, setFilterLocale] = useState(0);
    const history = useHistory();
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(null);
    const [defaultValue, setDefaultValue] = useState(null);
    const [defaultLocale, setDefaultLocale] = useState(null);
    const [defaultKey, setDefaultKey] = useState(null);

    const [headerFields, setHeaderFields] = React.useState([
        {name: 'id', value: 'ID', direction: 'asc', sorted: true},
        {name: 'place', value: 'Place', direction: 'asc', sorted: false},
        {name: 'locale', value: 'Locale', direction: 'asc', sorted: false},
        {name: 'name', value: 'Name', direction: 'asc', sorted: false},
        {name: 'key', value: 'Key', direction: 'asc', sorted: true},
        {name: 'value', value: 'Value', direction: 'asc', sorted: false},
        {name: 'actions', value: 'Actions', direction: 'asc', sorted: false},
    ])

    const renderActions = (translations) => {
        return (
            <EditIcon className="actionIcon"
                      onClick={() => handleOpenModal(translations.id, translations.value, translations.key, translations.locale)}/>
        )
    }

    const getTranslations = useCallback(async () => {
        try {
            const _params = new URLSearchParams(history.location.search)
            const response = await client.getAllTranslations({
                page: currentPage,
                size: rowsPerPage,
                ...searchParamsToObject(_params),
            })
            setTotal(response.totalElements)
            setTranslations(response.content);

        } catch (error) {
            notification.warning(error.message);
        } finally {
        }
    }, [currentPage, rowsPerPage]);

    useEffect(() => {
        getTranslations();
    }, [getTranslations]);

    const getLangs = useCallback(async () => {
        try {
            const response = await client.getAvailableLangs();
            setLangs(response);
            response.map((r, i) => (
                console.log(r.locale)
            ))
        } catch (error) {
            notification.warning(error.message);
        } finally {
        }
    }, [history]);

    useEffect(() => {
        getLangs();
    }, [getLangs]);


    const onChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setCurrentPage(0);
    };

    const search = (e) => {
        e.preventDefault();
        history.push("/translations?text=" + e.target.search.value);
        setFilterLocale(0);
        getTranslations();
    }

    const handleChangeFilterLocale = (e) => {
        const locale = e.target.value;
        setFilterLocale(locale);
        if (locale !== 0) {
            history.push("/translations?locale=" + locale);
        } else {
            history.push("/translations");
        }
        getTranslations();
    }

    const handleOpenModal = (id, value, key, locale) => {
        setId(id);
        setDefaultValue(value)
        setDefaultKey(key);
        setDefaultLocale(locale)
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setId(null)
        setDefaultValue(null)
        setDefaultKey(null)
        setDefaultValue(null)
        setOpenModal(false);
    };

    const change = async (e) => {
        try {
            e.preventDefault();
            await client.changeTranslationValue(id, {value: e.target.fieldValue.value});
            notification.success("Translation changed");
            getTranslations();
            handleCloseModal()
        } catch (e) {
            notification.warning(e.message || e.error);
        }
    }

    const modalBody = (
        <div className={'modal modalTranslations'}>
            <h2 id="simple-modal-title">Change translation value</h2>
            <p id="simple-modal-description">
                <p>Locale: {defaultLocale && defaultLocale}</p>
                <p>Key: {defaultKey && defaultKey}</p>
            </p>
            <div id="simple-modal-body">
                <form onSubmit={change}>
                    <TextField
                        id="value"
                        name="fieldValue"
                        label="Value"
                        multiline
                        rows={4}
                        fullWidth={true}
                        defaultValue={defaultValue && defaultValue}
                        variant="outlined"
                    />
                    <div className={'buttonContainer'}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );


    return (
        <>
            <Paper square>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                >
                    <Tab label="All" component={Link} to={'/translations'}/>
                    <Tab label="Add new lang" component={Link} to={'/translations/addNewLang'}/>
                    <Tab label="Add new record" component={Link} to={'/translations/addNewRecord'}/>
                </Tabs>
            </Paper>


            <Paper square className="searchPanel">
                <form onSubmit={search}>
                    <TextField
                        id="search"
                        name="search"
                        type="text"
                        placeholder="Search text"
                        autoComplete="off"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="searchButton"
                    >
                        Search
                    </Button>
                </form>
                {langs && (
                    <FormControl>

                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={filterLocale && filterLocale}
                            onChange={handleChangeFilterLocale}
                        >
                            <MenuItem key={0} value={0}>All</MenuItem>
                            {langs && langs.map((l, i) => (
                                <MenuItem key={i} value={l.locale}>{l.locale} ({l.name})</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Locale</FormHelperText>
                    </FormControl>
                )}
            </Paper>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {headerFields.map(f =>
                                <TableCell key={f.name}>{f.value}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {translations && translations.map((translation, index) => (
                            <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                                <TableCell>{translation.id}</TableCell>
                                <TableCell>{translation.place}</TableCell>
                                <TableCell>{translation.locale}</TableCell>
                                <TableCell>{translation.name}</TableCell>
                                <TableCell>{translation.key}</TableCell>
                                <TableCell>{translation.value}</TableCell>
                                <TableCell>{renderActions(translation)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>


            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalBody}
            </Modal>

        </>
    )
}
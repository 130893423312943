import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer,
  Button,
  Tabs,
  Tab,
  TextField,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select, MenuItem, FormHelperText
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {getFormattedDate, searchParamsToObject, toEur} from "../../utils/tools";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./Users.css";

export const Users = () => {
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [users, setUsers] = useState();
  const [searchEligibleForFastTrack, setSearchEligibleForFastTrack] = useState(false);
  const history = useHistory();
  const [activeField, setActiveField] = useState('id')
  const [filterFastTrack, setFilterFastTrack] = useState(0)
  const [filterUserType, setFilterUserType] = useState(0)
  const [filterStatus, setFilterStatus] = useState(0)
  const [filterGameType, setFilterGameType] = useState(0)
  const [filterJoinType, setFilterJoinType] = useState(0)
  const [filterUserTitle, setFilterUserTitle] = useState(0)
  const [filterRole, setFilterRole] = useState(0)
  const [selectedStartDate, setSelectedStartDate] = useState(new Date("2020-03-09"));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'username', value: 'Username', direction: 'asc', sorted: false},
    {name: 'refId', value: 'Ref', direction: 'asc', sorted: false},
    {name: 'country', value: 'Country', direction: 'asc', sorted: false},
    {name: 'email', value: 'Email', direction: 'asc', sorted: false},
    {name: 'status', value: 'Status', direction: 'asc', sorted: false},
    {name: 'earnedTotalCash', value: 'Total cash', direction: 'asc', sorted: false},
    {name: 'earnedTrading', value: 'Total trading', direction: 'asc', sorted: false},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
    {name: 'kycStatus', value: 'KYC status', direction: 'asc', sorted: false},
    {name: 'kycActions', value: 'KYC actions', direction: 'asc', sorted: false},
    {name: 'Actions', value: 'Actions', direction: 'asc', sorted: false},
  ])

  const getUsers = useCallback(async () => {
    try {
      const _params = new URLSearchParams(history.location.search)
      const response = await client.getAllUsers({
        page: currentPage,
        size: rowsPerPage,
        ...searchParamsToObject(_params),
      })
      setTotal(response.totalElements);
      setUsers(response.content);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    if (startDate == null || endDate == null) {
      history.push("?startDate=" + getFormattedDate(selectedStartDate) + "&endDate=" + getFormattedDate(selectedEndDate))
    }
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userType = params.get('userType');
    const status = params.get('status');
    const fastTrack = params.get('eligibleForFastTrack');
    const gameType = params.get('gameType');
    const joinType = params.get('joinType');
    const userTitle = params.get('userTitle');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    const role = params.get('role');
    if (userType) {
      setFilterUserType(userType);
    }
    if (status) {
      setFilterStatus(status);
    }
    if (fastTrack) {
      setFilterFastTrack(fastTrack);
    }
    if (gameType) {
      setFilterGameType(gameType);
    }
    if (joinType) {
      setFilterJoinType(joinType);
    }
    if (userTitle) {
      setFilterUserTitle(userTitle);
    }
    if (role) {
      setFilterRole(role);
    }
    if (startDate) {
      setSelectedStartDate(new Date(startDate))
    }
    if (endDate) {
      setSelectedEndDate(new Date(endDate))
    }
  }, [history]);

  const generateLink = (excludedVarName) => {
    const params = new URLSearchParams(history.location.search)
    const userType = params.get('userType');
    const status = params.get('status');
    const fastTrack = params.get('eligibleForFastTrack');
    const gameType = params.get('gameType');
    const joinType = params.get('joinType');
    const userTitle = params.get('userTitle');
    const role = params.get('role');
    const endDate = params.get("endDate");
    const startDate = params.get("startDate");
    let link = "?";
    if (userType !== null && excludedVarName !== "userType") {
      link = link + "&userType=" + userType;
    }
    if (status !== null && excludedVarName !== "status") {
      link = link + "&status=" + status;
    }
    if (fastTrack !== null && excludedVarName !== "fastTrack") {
      link = link + "&eligibleForFastTrack=" + fastTrack;
    }
    if (gameType !== null && excludedVarName !== "gameType") {
      link = link + "&gameType=" + gameType;
    }
    if (joinType !== null && excludedVarName !== "joinType") {
      link = link + "&joinType=" + joinType;
    }
    if (userTitle !== null && excludedVarName !== "userTitle") {
      link = link + "&userTitle=" + userTitle;
    }
    if (role !== null && excludedVarName !== "role") {
      link = link + "&role=" + role;
    }
    if (startDate !== null && excludedVarName !== "startDate") {
      link = link + "&startDate=" + startDate;
    }
    if (endDate !== null && excludedVarName !== "endDate") {
      link = link + "&endDate=" + endDate;
    }
    return link;
  }

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const renderUserLink = (userId) => {
    return (<Link to={`/users/${userId}/edit`}>{userId}</Link>);
  }

  const renderJoinType = (user) => {
    return (
      user.joinType === "WITH_TICKETS" ? ("+") : "-"
    );
  }

  const renderActions = (user) => {
    return (
      <Button
        variant="text"
        size="small"
        type="button"
        style={{color: '#0070f0', marginRight: 10}}
        onClick={() => history.push('/users/' + user.id + '/edit')}
      >
        Edit
      </Button>
    )
  }

  const renderKyc = (user) => {
    if (user.files) {
      return <div className="kyc-container">{user.files.map((el, i) => {
        let type = el.startsWith("proofOfId") ? "id" : "res";
        return <div key={i}>
          <div className="kyc-view-container" onClick={() => window.open("https://membersonly.org/uploads/kyc/" + user.internalId + "/" + el)}>View {type}</div>
          <div className="kyc-buttons-container">
            <div className="kyc-button kyc-button-approve" onClick={() => handleApproveKyc(user.id, type)}>Approve</div>
            <div className="kyc-button kyc-button-decline" onClick={() => handleDeclineKyc(user.id, type)}>Deny</div>
          </div>
        </div>
      })}
      </div>
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const renderStatus = (user) => {
    return (
      <>
        {user.status === "NEW" ? "New (not paid)" :
          user.status === "WAITING_FOR_PAYMENT" ? "Pending" :
            user.status === "MEMBER" ? "Active" :
              user.status
        }
      </>
    );
  }

  const renderFT = (user) => {
    return (
      <>
        {user.eligibleForFastTrack ? "Yes" : "No"}
      </>
    );
  }

  const search = (e) => {
    e.preventDefault();
    const link = generateLink("");
    history.push(link + "&text="+e.target.search.value);
    getUsers();
  }


  const handleChangeFilterUserType = (e) => {
    const userType = e.target.value;
    const link = generateLink("userType");
    setFilterUserType(userType);
    if (userType !== 0) {
      history.push(link + "&userType=" + userType);
    } else {
      history.push(link);
    }
    getUsers();
  }

  const handleChangeFilterFastTrack = (e) => {
    const fastTrack = e.target.value;
    const link = generateLink("fastTrack");
    setFilterFastTrack(fastTrack);
    if (fastTrack !== 0) {
      history.push(link + "&eligibleForFastTrack=" + fastTrack);
    } else {
      history.push(link)
    }
    getUsers();
  }

  const handleChangeFilterStatus = (e) => {
    const status = e.target.value;
    const link = generateLink("status");
    setFilterStatus(status);
    if (status !== 0) {
      history.push(link + "&status=" + status);
    } else {
      history.push(link);
    }
    getUsers();
  }

  const handleChangeFilterGameType = (e) => {
    const gameType = e.target.value;
    const link = generateLink("gameType");
    setFilterGameType(gameType);
    if (gameType !== 0) {
      history.push(link + "&gameType=" + gameType);
    } else {
      history.push(link);
    }
    getUsers();
  }

  const handleChangeFilterJoinType = (e) => {
    const joinType = e.target.value;
    const link = generateLink("joinType");
    setFilterJoinType(joinType);
    if (joinType !== 0) {
      history.push(link + "&joinType=" + joinType);
    } else {
      history.push(link);
    }
    getUsers();
  }

  const handleChangeFilterUserTitle = (e) => {
    const userTitle = e.target.value;
    const link = generateLink("userTitle");
    setFilterUserTitle(userTitle);
    if (userTitle !== 0) {
      history.push(link + "&userTitle=" + userTitle);
    } else {
      history.push(link);
    }
    getUsers();
  }

  const handleChangeFilterRole = (e) => {
    const role = e.target.value;
    const link = generateLink("role");
    setFilterRole(role);
    if (role !== 0) {
      history.push(link + "&role=" + role);
    } else {
      history.push(link);
    }
    getUsers();
  }

  const handleStartDateChange = (date) => {
    const link = generateLink("startDate");
    setSelectedStartDate(date);
    history.push(link + "&startDate=" + getFormattedDate(date));
    getUsers()
  };

  const handleEndDateChange = (date) => {
    const link = generateLink("endDate");
    setSelectedEndDate(date);
    history.push(link + "&endDate=" + getFormattedDate(date));
    getUsers()
  };

  const handleApproveKyc = async (id, type) => {
    await client.approveKyc(id, type)
      .then(() => notification.success("Document approved"))
      .catch((error) => notification.warning(error.message))
  }

  const handleDeclineKyc = async (id, type) => {
    await client.declineKyc(id, type)
      .then(() => notification.success("Document declined"))
      .catch((error) => notification.warning(error.message))
  }

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/users'}/>
        </Tabs>
      </Paper>
      <Paper square className="searchPanel">
        <form onSubmit={search}>
          <TextField
            id="search"
            name="search"
            type="text"
            placeholder="Search text"
            autoComplete="off"
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            size="small"
            type="submit"
            className="searchButton"
          >
            Search
          </Button>
        </form>
        <FormControl>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filterStatus && filterStatus}
            onChange={handleChangeFilterStatus}
          >
            <MenuItem key={0} value={0}>All</MenuItem>
            <MenuItem key={1} value={"NEW"}>New (not paid)</MenuItem>
            <MenuItem key={2} value={"WAITING_FOR_PAYMENT"}>Pending</MenuItem>
            <MenuItem key={3} value={"MEMBER"}>Active</MenuItem>
            <MenuItem key={4} value={"BANNED"}>Banned</MenuItem>
            <MenuItem key={4} value={"REFUNDED"}>Refunded</MenuItem>
          </Select>
          <FormHelperText>Status</FormHelperText>
        </FormControl>
        <FormControl>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filterGameType && filterGameType}
            onChange={handleChangeFilterGameType}
          >
            <MenuItem key={0} value={0}>All</MenuItem>
            <MenuItem key={1} value={"MEGA"}>Mega</MenuItem>
            <MenuItem key={2} value={"LITE"}>Lite</MenuItem>
          </Select>
          <FormHelperText>Game type</FormHelperText>
        </FormControl>
        <FormControl>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filterJoinType && filterJoinType}
            onChange={handleChangeFilterJoinType}
          >
            <MenuItem key={0} value={0}>All</MenuItem>
            <MenuItem key={1} value={"WITH_TICKETS"}>With tickets</MenuItem>
            <MenuItem key={2} value={"WITHOUT_TICKETS"}>Without tickets</MenuItem>
          </Select>
          <FormHelperText>Join type</FormHelperText>
        </FormControl>
        <FormControl>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filterFastTrack && filterFastTrack}
            onChange={handleChangeFilterFastTrack}
          >
            <MenuItem key={0} value={0}>All</MenuItem>
            <MenuItem key={1} value={"true"}>Yes</MenuItem>
            <MenuItem key={2} value={"false"}>No</MenuItem>
          </Select>
          <FormHelperText>Fast track</FormHelperText>
        </FormControl>
        <FormControl>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filterUserType && filterUserType}
            onChange={handleChangeFilterUserType}
          >
            <MenuItem key={0} value={0}>All</MenuItem>
            <MenuItem key={1} value={"PLAYER"}>Player</MenuItem>
            <MenuItem key={2} value={"MEMBER"}>Member</MenuItem>
          </Select>
          <FormHelperText>Team player</FormHelperText>
        </FormControl>
        <FormControl>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filterUserTitle && filterUserTitle}
            onChange={handleChangeFilterUserTitle}
          >
            <MenuItem key={0} value={0}>All</MenuItem>
            <MenuItem key={1} value={"NO_TITLE"}>No title</MenuItem>
            <MenuItem key={2} value={"GOLD"}>Gold</MenuItem>
            <MenuItem key={2} value={"PLATINUM"}>Platinum</MenuItem>
            <MenuItem key={2} value={"SAPPHIRE"}>Sapphire</MenuItem>
            <MenuItem key={2} value={"RUBY"}>Rubi</MenuItem>
            <MenuItem key={2} value={"EMERALD"}>Emerald</MenuItem>
            <MenuItem key={2} value={"DIAMOND"}>Diamond</MenuItem>
            <MenuItem key={2} value={"BLUE_DIAMOND"}>Blue diamond</MenuItem>
            <MenuItem key={2} value={"BLACK_DIAMOND"}>Black diamond</MenuItem>
            <MenuItem key={2} value={"CROWN_DIAMOND"}>Crown diamond</MenuItem>
            <MenuItem key={2} value={"AMBASSADOR"}>Ambassador</MenuItem>
            <MenuItem key={2} value={"GRAND_AMBASSADOR"}>Grand ambassador</MenuItem>
            <MenuItem key={2} value={"CROWN_AMBASSADOR"}>Crown ambassador</MenuItem>
          </Select>
          <FormHelperText>Title</FormHelperText>
        </FormControl>
        <FormControl>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filterRole && filterRole}
            onChange={handleChangeFilterRole}
          >
            <MenuItem key={0} value={0}>All</MenuItem>
            <MenuItem key={1} value={"ROLE_CLIENT"}>Client (User)</MenuItem>
            <MenuItem key={2} value={"ROLE_ADMIN"}>Admin</MenuItem>
            <MenuItem key={3} value={"ROLE_MODERATOR"}>Moderator</MenuItem>
            <MenuItem key={4} value={"ROLE_TRANSLATOR"}>Translator</MenuItem>
          </Select>
          <FormHelperText>Role</FormHelperText>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                id="date-picker-inline"
                style={{width: 140}}
                value={selectedStartDate}
                onChange={handleStartDateChange}
                autoOk={true}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
            />
            <FormHelperText>Start date</FormHelperText>
          </FormControl>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                id="date-picker-inline"
                style={{width: 140}}
                value={selectedEndDate}
                onChange={handleEndDateChange}
                autoOk={true}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
            />
            <FormHelperText>End date</FormHelperText>
          </FormControl>
        </MuiPickersUtilsProvider>

      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users && users.map((user, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{renderUserLink(user.refId)}</TableCell>
                <TableCell>{user.country}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{renderStatus(user)}</TableCell>
                <TableCell>{user.earned && toEur(user.earned.earnedTotalCash)}</TableCell>
                <TableCell>{user.earned && toEur(user.earned.earnedTotalTrading)}</TableCell>
                <TableCell>{user.createdAt}</TableCell>
                <TableCell>{user.kycStatus}</TableCell>
                <TableCell>{renderKyc(user)}</TableCell>
                <TableCell>{renderActions(user)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}
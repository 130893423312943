import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab, TextField, FormControl, Select, MenuItem, FormHelperText, Modal
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const Raffle = () => {
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [raffle, setRaffle] = useState();
  const history = useHistory();
  const [activeField, setActiveField] = useState('id');
  const [openModalGenerate, setOpenModalGenerate] = useState(false);

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'userId', value: 'User', direction: 'asc', sorted: false},
    {name: 'vctTokenId', value: 'Vct token', direction: 'asc', sorted: false},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: false},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
  ])

  const getRaffles = useCallback(async () => {
    try {
      const _params = new URLSearchParams(history.location.search)
      const response = await client.getAllRaffle({
        page: currentPage,
        size: rowsPerPage,
        ...searchParamsToObject(_params),
      })
      setTotal(response.totalElements)
      setRaffle(response.content);
    } catch (error) {
      notification.warning(error.message);
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getRaffles();
  }, [getRaffles]);


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const renderUserLink = (userId) => {
    return (<Link to={`/users/${userId}/edit`}>{userId}</Link>);
  }

  const renderVctTokenLink = (vctTokenId) => {
    return (<Link to={`/vctTokens/${vctTokenId}/edit`}>{vctTokenId}</Link>);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const generateRaffle = async (e) => {
    e.preventDefault();
    try {
      await client.generateRandomRaffle();
      handleCloseModalGenerate();
      notification.success("Raffel in progress. In will take 1 minute. Please refresh page later to see result")
    } catch (error) {
      handleCloseModalGenerate();
      notification.warning(error.message || error);
    }
  }

  const handleOpenModalGenerate = () => {
    setOpenModalGenerate(true);
  };
  const handleCloseModalGenerate = () => {
    setOpenModalGenerate(false);
  };

  const modalBodyGenerate= (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Generate random raffle</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={generateRaffle}>
        <div className={'modalBody'}>
          Are you sure you want to generate random raffle and allocate wins?
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalGenerate}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );


  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/raffle'}/>
        </Tabs>
      </Paper>

      <Paper square className="searchPanel">
          <Button
            variant="contained"
            size="small"
            type="submit"
            className="searchButton"
            onClick={handleOpenModalGenerate}
          >
            Generate raffle
          </Button>
      </Paper>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {raffle && raffle.map((r, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                <TableCell>{r.id}</TableCell>
                <TableCell>{renderUserLink(r.userId)}</TableCell>
                <TableCell>{renderVctTokenLink(r.vctTokenId)}</TableCell>
                <TableCell>{r.amount}</TableCell>
                <TableCell>{r.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Modal
        open={openModalGenerate}
        onClose={handleCloseModalGenerate}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyGenerate}
      </Modal>

    </>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {Card, Container, Paper, Tab, Tabs} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Bar, Line} from "react-chartjs-2";
import {defaults} from 'react-chartjs-2';

defaults.color = '#000';

export const Diagrams = () => {
  const [value, setValue] = useState(1);
  const [usersStat, setUsersStat] = useState();
  const [dates, setDates] = useState([]);
  const [usersCount, setUsersCount] = useState([]);
  const [paidUsersCount, setPaidUsersCount] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getUsersStat = useCallback(async () => {
    try {
      const response = await client.getDashboardUsersDiagramStat();
      setUsersStat(response);
      let _dates = [];
      let _usersCount = [];
      let _paidUsersCount = [];
      Object.entries(response.users).forEach(([k, v]) => {
        _dates[k] = v.date;
        _usersCount[k] = v.users;
        _paidUsersCount[k] = v.paidUsers;
      });
      setDates(_dates);
      setUsersCount(_usersCount);
      setPaidUsersCount(_paidUsersCount);
    } catch (error) {
      notification.warning(error.message);
    }
  }, []);

  useEffect(() => {
    getUsersStat();
  }, [getUsersStat]);

  const dataSet = {
    labels: dates && dates,
    datasets: [
      {
        label: 'Users',
        fill: false,
        lineTension: 0,
        backgroundColor: '#2387c8',
        borderColor: '#2387c8',
        borderWidth: 2,
        data: usersCount && usersCount
      },
      {
        label: 'Paid users',
        fill: false,
        lineTension: 0,
        backgroundColor: '#199D27',
        borderColor: '#199D27',
        borderWidth: 2,
        data: paidUsersCount && paidUsersCount
      }
    ]
  }

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="Stat" component={Link} to={'/dashboard'}/>
          <Tab label="Diagrams" component={Link} to={'/dashboard/diagrams'}/>
        </Tabs>
      </Paper>
      <Container maxWidth="xl" className={'dashboardContainer'} style={{marginTop: 30}}>
        <Card style={{width: "98%"}}>
          {dataSet && <Line
            data={dataSet}
            options={{
              title: {
                display: true,
                text: 'Users',
                fontSize: 20
              },
              legend: {
                display: true,
                position: 'left'
              },
              interaction: {
                intersect: false,
              },
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true
                  }
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: 'Value'
                  },
                  suggestedMin: -10,
                  suggestedMax: 200
                }
              }
            }}
          />
          }
        </Card>
      </Container>
    </>
  );
}
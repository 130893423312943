import React, {useCallback, useEffect, useState} from "react";
import {
  Paper,
  Tabs, Tab, Card, CardContent, Container, Typography, CircularProgress
} from "@material-ui/core";
import {Link} from "react-router-dom";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {toEur} from "../../utils/tools";

export const Dashboard = () => {
  const [value, setValue] = useState(0);
  const [stat, setStat] = useState();
  const [ticketsStat, setTicketsStat] = useState();
  const [winsStat, setWinsStat] = useState();
  const [usersStat, setUsersStat] = useState();
  const price = 450;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStat = useCallback(async () => {
    try {
      const response = await client.getDashboardStat();
      setStat(response);
    } catch (error) {
      notification.warning(error.message);
    }
  }, []);

  useEffect(() => {
    getStat();
  }, [getStat]);

  const getTicketsStat = useCallback(async () => {
    try {
      const response = await client.getDashboardTicketsStat();
      setTicketsStat(response);
    } catch (error) {
      notification.warning(error.message);
    }
  }, []);

  useEffect(() => {
    getTicketsStat();
  }, [getTicketsStat]);

  const getWinsStat = useCallback(async () => {
    try {
      const response = await client.getDashboardWinsStat();
      setWinsStat(response);
    } catch (error) {
      notification.warning(error.message);
    }
  }, []);

  useEffect(() => {
    getWinsStat();
  }, [getWinsStat]);


  const getUsersStat = useCallback(async () => {
    try {
      const response = await client.getDashboardUsersStat();
      setUsersStat(response);
    } catch (error) {
      notification.warning(error.message);
    }
  }, []);

  useEffect(() => {
    getUsersStat();
  }, [getUsersStat]);

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="Stat" component={Link} to={'/dashboard'}/>
          <Tab label="Diagrams" component={Link} to={'/dashboard/diagrams'}/>
        </Tabs>
      </Paper>

      <Container maxWidth="xl" className={'dashboardContainer'} style={{marginTop: 10}}>
        <Card>
          <CardContent>
            <Typography variant="h5" color="textSecondary">Users</Typography>
            {usersStat ?
              <table className="tableStat">
                <thead>
                <th>Period</th>
                <th>Paid</th>
                <th>Total</th>
                </thead>
                <tr>
                  <td>Today</td>
                  <td>{usersStat.totalPaidUsersToday}</td>
                  <td>{usersStat.totalUsersToday}</td>
                </tr>
                <tr>
                  <td>Yesterday</td>
                  <td>{usersStat.totalPaidUsersYesterday}</td>
                  <td>{usersStat.totalUsersYesterday}</td>
                </tr>
                <tr>
                  <td>This week</td>
                  <td>{usersStat.totalPaidUsersThisWeek}</td>
                  <td>{usersStat.totalUsersThisWeek}</td>
                </tr>
                <tr>
                  <td>Last week</td>
                  <td>{usersStat.totalPaidUsersLastWeek}</td>
                  <td>{usersStat.totalUsersLastWeek}</td>
                </tr>
                <tr>
                  <td>This month</td>
                  <td>{usersStat.totalPaidUsersThisMonth}</td>
                  <td>{usersStat.totalUsersThisMonth}</td>
                </tr>
                <tr>
                  <td>Last month</td>
                  <td>{usersStat.totalPaidUsersLastMonth}</td>
                  <td>{usersStat.totalUsersLastMonth}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{usersStat.totalPaidUsers}</td>
                  <td>{usersStat.totalUsers}</td>
                </tr>
              </table>
              : <div style={{textAlign: "center"}}><CircularProgress/></div>}
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h5" color="textSecondary">Tickets</Typography>
            {stat ?
              <table className="tableStat">
                <thead>
                <th>Period</th>
                <th>Lite</th>
                <th>Mega</th>
                <th>Giga</th>
                <th>Total</th>
                </thead>
                <tr>
                  <td>Today</td>
                  <td>{toEur(stat.totalTicketsRevenueTodayLite)}</td>
                  <td>{toEur(stat.totalTicketsRevenueTodayMega)}</td>
                  <td>{toEur(stat.totalTicketsRevenueTodayGiga)}</td>
                  <td>{toEur(stat.totalTicketsRevenueToday)}</td>
                </tr>
                <tr>
                  <td>Yesterday</td>
                  <td>{toEur(stat.totalTicketsRevenueYesterdayLite)}</td>
                  <td>{toEur(stat.totalTicketsRevenueYesterdayMega)}</td>
                  <td>{toEur(stat.totalTicketsRevenueYesterdayGiga)}</td>
                  <td>{toEur(stat.totalTicketsRevenueYesterday)}</td>
                </tr>
                <tr>
                  <td>This week</td>
                  <td>{toEur(stat.totalTicketsRevenueThisWeekLite)}</td>
                  <td>{toEur(stat.totalTicketsRevenueThisWeekMega)}</td>
                  <td>{toEur(stat.totalTicketsRevenueThisWeekGiga)}</td>
                  <td>{toEur(stat.totalTicketsRevenueThisWeek)}</td>
                </tr>
                <tr>
                  <td>Last week</td>
                  <td>{toEur(stat.totalTicketsRevenueLastWeekLite)}</td>
                  <td>{toEur(stat.totalTicketsRevenueLastWeekMega)}</td>
                  <td>{toEur(stat.totalTicketsRevenueLastWeekGiga)}</td>
                  <td>{toEur(stat.totalTicketsRevenueLastWeek)}</td>
                </tr>
                <tr>
                  <td>This month</td>
                  <td>{toEur(stat.totalTicketsRevenueThisMonthLite)}</td>
                  <td>{toEur(stat.totalTicketsRevenueThisMonthMega)}</td>
                  <td>{toEur(stat.totalTicketsRevenueThisMonthGiga)}</td>
                  <td>{toEur(stat.totalTicketsRevenueThisMonth)}</td>
                </tr>
                <tr>
                  <td>Last month</td>
                  <td>{toEur(stat.totalTicketsRevenueLastMonthLite)}</td>
                  <td>{toEur(stat.totalTicketsRevenueLastMonthMega)}</td>
                  <td>{toEur(stat.totalTicketsRevenueLastMonthGiga)}</td>
                  <td>{toEur(stat.totalTicketsRevenueLastMonth)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{toEur(stat.totalTicketsRevenueLite)}</td>
                  <td>{toEur(stat.totalTicketsRevenueMega)}</td>
                  <td>{toEur(stat.totalTicketsRevenueGiga)}</td>
                  <td>{toEur(stat.totalTicketsRevenue)}</td>
                </tr>
              </table>
              : <div style={{textAlign: "center"}}><CircularProgress/></div>}
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h5" color="textSecondary">VCT Sales</Typography>
            {stat ?
              <table className="tableStat">
                <thead>
                <th>Period</th>
                <th>Amount</th>
                </thead>
                <tr>
                  <td>Today</td>
                  <td>{toEur(stat.totalVctSalesToday)}</td>
                </tr>
                <tr>
                  <td>Yesterday</td>
                  <td>{toEur(stat.totalVctSalesYesterday)}</td>
                </tr>
                <tr>
                  <td>This week</td>
                  <td>{toEur(stat.totalVctSalesThisWeek)}</td>
                </tr>
                <tr>
                  <td>Last week</td>
                  <td>{toEur(stat.totalVctSalesLastWeek)}</td>
                </tr>
                <tr>
                  <td>This month</td>
                  <td>{toEur(stat.totalVctSalesThisMonth)}</td>
                </tr>
                <tr>
                  <td>Last month</td>
                  <td>{toEur(stat.totalVctSalesLastMonth)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{toEur(stat.totalVctSales)}</td>
                </tr>
              </table>
              : <div style={{textAlign: "center"}}><CircularProgress/></div>}
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h5" color="textSecondary">Wins</Typography>
            {winsStat ?
              <table className="tableStat">
                <thead>
                <th>Period</th>
                <th>Cashback</th>
                <th>Main draw</th>
                <th>Lucky draw</th>
                <th>Team wins</th>
                </thead>
                <tr>
                  <td>Today</td>
                  <td>{toEur(winsStat.totalCashbackToday)}</td>
                  <td>{toEur(winsStat.totalMainDrawToday)}</td>
                  <td>{toEur(winsStat.totalLuckyDrawToday)}</td>
                  <td>{toEur(winsStat.totalTeamWinsToday)}</td>
                </tr>
                <tr>
                  <td>Yesterday</td>
                  <td>{toEur(winsStat.totalCashbackYesterday)}</td>
                  <td>{toEur(winsStat.totalMainDrawYesterday)}</td>
                  <td>{toEur(winsStat.totalLuckyDrawYesterday)}</td>
                  <td>{toEur(winsStat.totalTeamWinsYesterday)}</td>
                </tr>
                <tr>
                  <td>This week</td>
                  <td>{toEur(winsStat.totalCashbackThisWeek)}</td>
                  <td>{toEur(winsStat.totalMainDrawThisWeek)}</td>
                  <td>{toEur(winsStat.totalLuckyDrawThisWeek)}</td>
                  <td>{toEur(winsStat.totalTeamWinsThisWeek)}</td>
                </tr>
                <tr>
                  <td>Last week</td>
                  <td>{toEur(winsStat.totalCashbackLastWeek)}</td>
                  <td>{toEur(winsStat.totalMainDrawLastWeek)}</td>
                  <td>{toEur(winsStat.totalLuckyDrawLastWeek)}</td>
                  <td>{toEur(winsStat.totalTeamWinsLastWeek)}</td>
                </tr>
                <tr>
                  <td>This month</td>
                  <td>{toEur(winsStat.totalCashbackThisMonth)}</td>
                  <td>{toEur(winsStat.totalMainDrawThisMonth)}</td>
                  <td>{toEur(winsStat.totalLuckyDrawThisMonth)}</td>
                  <td>{toEur(winsStat.totalTeamWinsThisMonth)}</td>
                </tr>
                <tr>
                  <td>Last month</td>
                  <td>{toEur(winsStat.totalCashbackLastMonth)}</td>
                  <td>{toEur(winsStat.totalMainDrawLastMonth)}</td>
                  <td>{toEur(winsStat.totalLuckyDrawLastMonth)}</td>
                  <td>{toEur(winsStat.totalTeamWinsLastMonth)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{toEur(winsStat.totalCashback)}</td>
                  <td>{toEur(winsStat.totalMainDraw)}</td>
                  <td>{toEur(winsStat.totalLuckyDraw)}</td>
                  <td>{toEur(winsStat.totalTeamWins)}</td>
                </tr>
              </table>
              : <div style={{textAlign: "center"}}><CircularProgress/></div>}
          </CardContent>
        </Card>
      </Container>

      <Container maxWidth="xl" className={'dashboardContainer'} style={{marginTop: 10, marginLeft: 0, marginRight: 0}}>
        <Card>
          <CardContent>
            <Typography variant="h5" color="textSecondary">Total stat</Typography>
            {stat ?
              <table className="tableStat">
                <tr>
                  <td>Cash wallets</td>
                  <td>{toEur(stat.totalCash)} / hold: {toEur(stat.totalHoldCash)}</td>
                </tr>
                <tr>
                  <td>Trading wallets</td>
                  <td>{toEur(stat.totalTrading)} / hold: {toEur(stat.totalHoldTrading)}</td>
                </tr>
                <tr>
                  <td>PPCs members</td>
                  <td>{stat.ppcMembers}</td>
                </tr>
                <tr>
                  <td>PPC traded</td>
                  <td>{stat.ppcTraded}</td>
                </tr>
                <tr>
                  <td>PPC packs sold</td>
                  <td>{stat.totalPpcPackSold} / {toEur(stat.totalPpcPackSoldValue)}</td>
                </tr>
                <tr>
                  <td>PPC rate</td>
                  <td>{toEur(stat.ppcRate)}</td>
                </tr>
                <tr>
                  <td>VCT rate</td>
                  <td>{toEur(stat.vctRate)}</td>
                </tr>
              </table>
              : <div style={{textAlign: "center"}}><CircularProgress/></div>}
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h5" color="textSecondary">Total tickets</Typography>
            {ticketsStat ?
              <table className="tableStat">
                <thead>
                <tr>
                <th>Type</th>
                <th style={{paddingRight: 30}} colSpan={2}>Lite</th>
                <th style={{paddingRight: 30}} colSpan={2}>Mega</th>
                <th style={{paddingRight: 30}} colSpan={2}>Giga</th>
                </tr>
                <tr>
                  <th/>
                  <th>Qty</th>
                  <th>Insurance</th>
                  <th>Qty</th>
                  <th>Insurance</th>
                  <th>Qty</th>
                  <th>Insurance</th>
                </tr>
                </thead>
                <tr>
                  <td>Active</td>
                  <td>{ticketsStat.totalActiveTicketsLite}</td>
                  <td>{toEur(ticketsStat.totalActiveTicketsLite * 0.045)}</td>

                  <td>{ticketsStat.totalActiveTicketsMega}</td>
                  <td>{toEur(ticketsStat.totalActiveTicketsMega * 0.45)}</td>

                  <td>{ticketsStat.totalActiveTicketsGiga}</td>
                  <td>?</td>
                </tr>
                <tr>
                  <td>Pending</td>
                  <td>{ticketsStat.totalPendingTicketsLite}</td>
                  <td>{toEur(ticketsStat.totalPendingTicketsLite * 0.045)}</td>

                  <td>{ticketsStat.totalPendingTicketsMega}</td>
                  <td>{toEur(ticketsStat.totalPendingTicketsMega * 0.45)}</td>

                  <td>{ticketsStat.totalPendingTicketsGiga}</td>
                  <td>?</td>
                </tr>
                <tr>
                  <td>History</td>
                  <td>{ticketsStat.totalHistoryTicketsLite}</td>
                  <td>{toEur(ticketsStat.totalHistoryTicketsLite * 0.045)}</td>

                  <td>{ticketsStat.totalHistoryTicketsMega}</td>
                  <td>{toEur(ticketsStat.totalHistoryTicketsMega * 0.45)}</td>

                  <td>{ticketsStat.totalHistoryTicketsGiga}</td>
                  <td>?</td>
                </tr>
              </table>
              : <div style={{textAlign: "center"}}><CircularProgress/></div>}
          </CardContent>
        </Card>
      </Container>

    </>
  )
}
import { jwt } from './jwt';
import { api } from './api';

import { API_URLS } from './apiUrls';

const BASIC_CODE = process.env.REACT_APP_BASIC_CODE;

export class Client {

  //Users

  auth2fa = ({ email, password }) =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  refreshToken = () =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=refresh_token&refresh_token=${jwt.refreshToken}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  //Users
  getCurrentUser = () => api.get(API_URLS.USERS.CURRENT);
  getAllUsers = (params) => api.get(API_URLS.USERS.ALL, {params})
  getUserById = (id) => api.get(API_URLS.USERS.GET_BY_ID(id))
  updateProfile = (id, data) => api.put(API_URLS.USERS.UPDATE(id), data)
  getLastLogin = (id) => api.get(API_URLS.USERS.GET_LAST_LOGIN(id))
  changeJoinType = (id) => api.put(API_URLS.USERS.CHANGE_JOIN_TYPE(id))
  refundUser = (id) => api.put(API_URLS.USERS.REFUND(id))
  approveKyc = (id, type) => api.put(API_URLS.USERS.APPROVE_KYC(id, type))
  declineKyc = (id, type) => api.put(API_URLS.USERS.DECLINE_KYC(id, type))
  getLoginHistory = (params) => api.get(API_URLS.USERS.LOGIN_HISTORY, {params})

  //Wallets
  getAllWallets = (params) => api.get(API_URLS.WALLETS.ALL, {params})
  getWalletById = (id) => api.get(API_URLS.WALLETS.GET_BY_ID(id))
  topupWallet = (data) => api.post(API_URLS.WALLETS.TOPUP, data)
  withdrawFromWallet = (data) => api.post(API_URLS.WALLETS.WITHDRAW, data)
  moveVCT = (data) => api.post(API_URLS.WALLETS.MOVE_VCT, data)
  getWalletsCSV = () => api.get(API_URLS.WALLETS.CSV)

  //Transactions
  getAllTransactions = (params) => api.get(API_URLS.TRANSACTIONS.ALL, {params})
  getTransactionById = (id) => api.get(API_URLS.TRANSACTIONS.GET_BY_ID(id))
  revokeTeamCashback = (id) => api.put(API_URLS.TRANSACTIONS.REVOKE_CASHBACK(id))

  //Translations
  getAllTranslations = (params) => api.get(API_URLS.TRANSLATIONS.ALL, {params})
  getAvailableLangs = () => api.get(API_URLS.TRANSLATIONS.GET_AVAILABLE_LANGS)
  addTranslationLang = (data) => api.post(API_URLS.TRANSLATIONS.ADD_NEW_LANG, data)
  addTranslationKey = (data) => api.post(API_URLS.TRANSLATIONS.ADD_NEW_KEY, data)
  getTranslationById = (id) => api.get(API_URLS.TRANSLATIONS.GET_BY_ID(id))
  changeTranslationValue = (id, data) => api.put(API_URLS.TRANSLATIONS.CHANGE_VALUE(id), data)

  //Payouts
  getAllPayouts = (params) => api.get(API_URLS.PAYOUTS.ALL, {params})
  getPayoutById = (id) => api.get(API_URLS.PAYOUTS.GET_BY_ID(id))
  confirmPayout = (id) => api.put(API_URLS.PAYOUTS.CONFIRM_PAYOUT(id))
  declinePayout = (id) => api.put(API_URLS.PAYOUTS.DECLINE_PAYOUT(id))

  //Tickets
  getAllTickets = (params) => api.get(API_URLS.TICKETS.ALL, {params})
  getTicketById = (id) => api.get(API_URLS.TICKETS.GET_BY_ID(id))

  //Winners
  getAllWinners = (params) => api.get(API_URLS.WINNERS.ALL, {params})
  getWinnerById = (id) => api.get(API_URLS.WINNERS.GET_BY_ID(id))
  payManualWin = (data) => api.post(API_URLS.WINNERS.PAY_MANUAL, data)

  //Orders
  getAllOrders = (params) => api.get(API_URLS.ORDERS.ALL, {params})
  getInvoices = (params) => api.get(API_URLS.ORDERS.INVOICES, {params})
  getArunaOrders = (params) => api.get(API_URLS.ORDERS.ARUNA, {params})
  getVctOrders = (params) => api.get(API_URLS.ORDERS.VCT, {params})
  getPpcOrders = (params) => api.get(API_URLS.ORDERS.PPC, {params})
  getTicketOrders = (params) => api.get(API_URLS.ORDERS.TICKETS, {params})
  getOrderById = (id) => api.get(API_URLS.ORDERS.GET_BY_ID(id))
  confirmInvoice = (id) => api.put(API_URLS.ORDERS.CONFIRM_INVOICE(id))

  //Exchange
  getAllExchangeOrders = (params) => api.get(API_URLS.EXCHANGE.ALL, {params})
  getExchangeOrderById = (id) => api.get(API_URLS.EXCHANGE.GET_BY_ID(id))

  //Vouchers
  getAllVouchers = (params) => api.get(API_URLS.VOUCHERS.ALL, {params})
  getVouchersById = (id) => api.get(API_URLS.VOUCHERS.GET_BY_ID(id))

  //Stat
  getDashboardStat = () => api.get(API_URLS.STAT.DASHBOARD)
  getDashboardTicketsStat = () => api.get(API_URLS.STAT.DASHBOARD_TICKETS)
  getDashboardWinsStat = () => api.get(API_URLS.STAT.DASHBOARD_WINS)
  getDashboardUsersStat = () => api.get(API_URLS.STAT.DASHBOARD_USERS)
  getDashboardUsersDiagramStat = () => api.get(API_URLS.STAT.DASHBOARD_USERS_DIAGRAM)
  getUserOrganisationStat = (id) => api.get(API_URLS.STAT.GET_USER_ORGANISATION_STAT(id))

  //Aruna
  getAllAruna = (params) => api.get(API_URLS.ARUNA.ALL, {params})
  getArunaById = (id) => api.get(API_URLS.ARUNA.GET_BY_ID(id))


  //VCT
  getAllVct = (params) => api.get(API_URLS.VCT.ALL, {params})
  getVctById = (id) => api.get(API_URLS.VCT.GET_BY_ID(id))

  //Voting
  getVotes = (params) => api.get(API_URLS.VOTING.ALL, {params})

  //Raffle
  generateRandomRaffle = () => api.post(API_URLS.RAFFLE.GENERATE)
  getAllRaffle = (params) => api.get(API_URLS.RAFFLE.ALL, {params})
  getRaffleById = (id) => api.get(API_URLS.RAFFLE.GET_BY_ID(id))

  //Pool
  getPoolStat = (params) => api.get(API_URLS.POOL.STAT, {params})
}

export const client = new Client();

import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";

export const Winners = () => {
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [winners, setWinners] = useState();
  const history = useHistory();
  const [activeField, setActiveField] = useState('id')

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'ticketId', value: 'Ticket', direction: 'asc', sorted: false},
    {name: 'result', value: 'Result', direction: 'asc', sorted: false},
    {name: 'resolved', value: 'Resolved', direction: 'asc', sorted: false},
    {name: 'statusDate', value: 'Status date', direction: 'asc', sorted: false},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: false},
    {name: 'wonNumbers', value: 'Won numbers', direction: 'asc', sorted: false},
    {name: 'type', value: 'Type', direction: 'asc', sorted: false},
  ])

  const getWinners = useCallback(async () => {
    try {
      const _params = new URLSearchParams(history.location.search)
      const response = await client.getAllWinners({
        page: currentPage,
        size: rowsPerPage,
        ...searchParamsToObject(_params),
      })
      setWinners(response.content);
      setTotal(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getWinners();
  }, [getWinners]);


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const renderActions = (winner) => {
    return (
      <Button
        variant="text"
        size="small"
        type="button"
        style={{color: '#0070f0', marginRight: 10}}
        onClick={() => history.push('/winners/'+winner.id+'/edit')}
      >
        Edit
      </Button>
    )
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/winners'}/>
        </Tabs>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {winners && winners.map((winner, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                <TableCell>{winner.id}</TableCell>
                <TableCell>{winner.ticketId}</TableCell>
                <TableCell>{winner.result}</TableCell>
                <TableCell>{winner.resolved ? "Yes" : "No"}</TableCell>
                <TableCell>{winner.statusDate}</TableCell>
                <TableCell>{winner.amount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR'
                })}</TableCell>
                <TableCell>{winner.wonNumbers}</TableCell>
                <TableCell>{winner.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}
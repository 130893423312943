import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab, TextField, Modal, Select, MenuItem, FormHelperText, Tooltip
} from "@material-ui/core";
import {Link, useHistory, useParams} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {useDispatch} from "react-redux";
import countries from '../../constants/country';
import {Cancel, EmojiEvents, ThumbUp} from "@material-ui/icons";

export const UsersEdit = () => {
  const dispatch = useDispatch();
  const [isSubmittingProfile, setIsSubmittingProfile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(1);
  const [user, setUser] = useState();
  const [lastLogin, setLastLogin] = useState();
  const [currentTransactionsPage, setCurrentTransactionsPage] = useState(0)
  const [rowsPerTransactionsPage, setRowsPerTransactionsPage] = useState(10);
  const [totalTransactions, setTotalTransactions] = useState(1);
  const [transactions, setTransactions] = useState();

  const [currentLoginHistoryPage, setCurrentLoginHistoryPage] = useState(0)
  const [rowsPerLoginHistoryPage, setRowsPerLoginHistoryPage] = useState(10);
  const [totalLoginHistory, setTotalLoginHistory] = useState(1);
  const [loginHistory, setLoginHistory] = useState();

  const [currentExchangeOrdersPage, setCurrentExchangeOrdersPage] = useState(0)
  const [rowsPerExchangeOrdersPage, setRowsPerExchangeOrdersPage] = useState(10);
  const [totalExchangeOrders, setTotalExchangeOrders] = useState(1);
  const [exchangeOrders, setExchangeOrders] = useState();

  const [currentFirstLinePage, setCurrentFirstLinePage] = useState(0)
  const [rowsPerFirstLinePage, setRowsPerFirstLinePage] = useState(10);
  const [totalFirstLine, setTotalFirstLine] = useState(1);
  const [firstLine, setFirstLine] = useState();

  const [currentVouchersPage, setCurrentVouchersPage] = useState(0)
  const [rowsPerVouchersPage, setRowsPerVouchersPage] = useState(10);
  const [totalVouchers, setTotalVouchers] = useState(1);
  const [vouchers, setVouchers] = useState();

  const [currentTicketsPage, setCurrentTicketsPage] = useState(0)
  const [rowsPerTicketsPage, setRowsPerTicketsPage] = useState(10);
  const [totalTickets, setTotalTickets] = useState(1);
  const [tickets, setTickets] = useState();

  const [currentOrdersPage, setCurrentOrdersPage] = useState(0)
  const [rowsPerOrdersPage, setRowsPerOrdersPage] = useState(10);
  const [totalOrders, setTotalOrders] = useState(1);
  const [orders, setOrders] = useState();

  const [wallet, setWallet] = useState();
  const [stat, setStat] = useState();
  const {id} = useParams()

  const [openModalTopupCash, setOpenModalTopupCash] = useState(false);
  const [openModalTopupTrading, setOpenModalTopupTrading] = useState(false);
  const [openModalTopupPpc, setOpenModalTopupPpc] = useState(false);
  const [openModalTopupVct, setOpenModalTopupVct] = useState(false);
  const [openModalTopupCgt, setOpenModalTopupCgt] = useState(false);
  const [openModalWithdrawCash, setOpenModalWithdrawCash] = useState(false);
  const [openModalWithdrawTrading, setOpenModalWithdrawTrading] = useState(false);
  const [openModalWithdrawPpc, setOpenModalWithdrawPpc] = useState(false);
  const [openModalWithdrawVct, setOpenModalWithdrawVct] = useState(false);
  const [openModalChangeJoinType, setOpenModalChangeJoinType] = useState(false);
  const [openModalRefund, setOpenModalRefund] = useState(false);
  const [openModalMoveVct, setOpenModalMoveVct] = useState(false);
  const [openModalWin, setOpenModalWin] = useState(false);

  const [defaultValue, setDefaultValue] = useState(0.00)

  const [ticketId, setTicketId] = useState()


  const {handleSubmit, reset, control, formState: {errors}} = useForm();

  const onSubmit = async (data) => {
    try {
      await client.updateProfile(user.id, data);
      notification.success("Profile updated");
    } catch (e) {
      notification.warning(e.message || e.error);
    }
  }

  const handleOpenModalTopupCash = () => {
    setOpenModalTopupCash(true);
  };
  const handleCloseModalTopupCash = () => {
    setOpenModalTopupCash(false);
  };
  const handleOpenModalTopupTrading = () => {
    setOpenModalTopupTrading(true);
  };
  const handleCloseModalTopupTrading = () => {
    setOpenModalTopupTrading(false);
  };
  const handleOpenModalTopupPpc = () => {
    setOpenModalTopupPpc(true);
  };
  const handleCloseModalTopupPpc = () => {
    setOpenModalTopupPpc(false);
  };

  const handleOpenModalTopupVct = () => {
    setOpenModalTopupVct(true);
  };
  const handleCloseModalTopupVct = () => {
    setOpenModalTopupVct(false);
  };

  const handleOpenModalTopupCgt = () => {
    setOpenModalTopupCgt(true);
  };
  const handleCloseModalTopupCgt = () => {
    setOpenModalTopupCgt(false);
  };

  const handleOpenModalWithdrawCash = () => {
    setOpenModalWithdrawCash(true);
  };
  const handleCloseModalWithdrawCash = () => {
    setOpenModalWithdrawCash(false);
  };
  const handleOpenModalWithdrawTrading = () => {
    setOpenModalWithdrawTrading(true);
  };
  const handleCloseModalWithdrawTrading = () => {
    setOpenModalWithdrawTrading(false);
  };
  const handleOpenModalWithdrawPpc = () => {
    setOpenModalWithdrawPpc(true);
  };
  const handleCloseModalWithdrawPpc = () => {
    setOpenModalWithdrawPpc(false);
  };
  const handleOpenModalWithdrawVct = () => {
    setOpenModalWithdrawVct(true);
  };
  const handleCloseModalWithdrawVct = () => {
    setOpenModalWithdrawVct(false);
  };

  const handleOpenModalChangeJoinType = () => {
    setOpenModalChangeJoinType(true);
  };
  const handleCloseModalChangeJoinType = () => {
    setOpenModalChangeJoinType(false);
  };

  const handleOpenModalRefund = () => {
    setOpenModalRefund(true);
  };
  const handleCloseModalRefund = () => {
    setOpenModalRefund(false);
  };

  const handleOpenModalMoveVct = () => {
    setOpenModalMoveVct(true);
  };
  const handleCloseModalMoveVct = () => {
    setOpenModalMoveVct(false);
  };

  const handleOpenModalWin = (ticketId) => {
    setTicketId(ticketId)
    setOpenModalWin(true);
  };
  const handleCloseModalWin = () => {
    setOpenModalWin(false);
  };

  const countryList = countries.map(c => ({
    value: `${c.id}`,
    text: c.name,
    flagKey: c.iso,
  }));

  const [headerTransactionsFields, setHeaderTransactionsFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'user', value: 'User', direction: 'asc', sorted: false},
    {name: 'initUser', value: 'Init user', direction: 'asc', sorted: false},
    {name: 'amountOriginal', value: 'Original', direction: 'asc', sorted: false},
    {name: 'amountCash', value: 'Cash', direction: 'asc', sorted: false},
    {name: 'amountTrading', value: 'Trading', direction: 'asc', sorted: false},
    {name: 'amountPpc', value: 'PPC', direction: 'asc', sorted: false},
    {name: 'type', value: 'Type', direction: 'asc', sorted: false},
    {name: 'ioType', value: 'I/O', direction: 'asc', sorted: false},
    {name: 'reason', value: 'Note', direction: 'asc', sorted: false},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
  ])

  const getTransactions = useCallback(async () => {
    try {
      const response = await client.getAllTransactions({
        page: currentTransactionsPage,
        size: rowsPerTransactionsPage,
        userId: id
      });

      setTransactions(response.content);
      setTotalTransactions(response.totalElements);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentTransactionsPage, rowsPerTransactionsPage, id]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions, id]);

  const onChangeTransactionsPage = (_, newPage) => {
    setCurrentTransactionsPage(newPage)
  }

  const handleChangeRowsPerTransactionsPage = (e) => {
    setRowsPerTransactionsPage(e.target.value);
    setCurrentTransactionsPage(0);
  };

  const [headerExchangeOrdersFields, setHeaderExchangeOrdersFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'userId', value: 'User', direction: 'asc', sorted: true},
    {name: 'executorUerId', value: 'Executor', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'price', value: 'Price', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
    {name: 'executedAt', value: 'Completed', direction: 'asc', sorted: false},
  ])

  const getExchangeOrders = useCallback(async () => {
    try {
      const response = await client.getAllExchangeOrders({
        page: currentExchangeOrdersPage,
        size: rowsPerExchangeOrdersPage,
        userId: id,
        executorId: id
      })
      setExchangeOrders(response.content);
      setTotalExchangeOrders(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentExchangeOrdersPage, rowsPerExchangeOrdersPage, id]);

  useEffect(() => {
    getExchangeOrders();
  }, [currentExchangeOrdersPage, id]);

  const onChangeExchangeOrdersPage = (_, newPage) => {
    setCurrentExchangeOrdersPage(newPage)
  }

  const handleChangeRowsPerExchangeOrdersPage = (e) => {
    setRowsPerExchangeOrdersPage(e.target.value);
    setCurrentExchangeOrdersPage(0);
  };

  const [headerOrdersFields, setHeaderOrdersFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Price', direction: 'asc', sorted: true},
    {name: 'cryptoAmount', value: 'Crypto amount', direction: 'asc', sorted: true},
    {name: 'cryptoAddress', value: 'Crypto addr', direction: 'asc', sorted: true},
    {name: 'fixedCryptoRate', value: 'Crypto rate', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
  ])

  const getOrders = useCallback(async () => {
    try {
      const response = await client.getAllOrders({
        page: currentOrdersPage,
        size: rowsPerOrdersPage,
        userId: id
      })
      setOrders(response.content);
      setTotalOrders(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentOrdersPage, rowsPerOrdersPage, id]);

  useEffect(() => {
    getOrders();
  }, [currentOrdersPage, id]);

  const onChangeOrdersPage = (_, newPage) => {
    setCurrentOrdersPage(newPage)
  }

  const handleChangeRowsPerOrdersPage = (e) => {
    setRowsPerOrdersPage(e.target.value);
    setCurrentOrdersPage(0);
  };

  const renderUserLink = (userId) => {
    return (<Link to={`/users/${userId}/edit`}>{userId}</Link>);
  }
  const renderOrderLink = (orderId) => {
    return (<Link to={`/orders/${orderId}/edit`}>{orderId}</Link>);
  }
  const renderStatus = (user) => {
    return (
      <>
        {user.status === "NEW" ? "New (not paid)" :
          user.status === "WAITING_FOR_PAYMENT" ? "Pending" :
            user.status === "MEMBER" ? "Active" :
              user.status
        }
      </>
    );
  }
  const renderChangeJoinTypeButton = (user) => {
    return (
      user.joinType === "WITHOUT_TICKETS" && user.status === "MEMBER" && (
        <Button
          variant="contained"
          size="small"
          type="submit"
          className="actionButtons"
          onClick={handleOpenModalChangeJoinType}
        >
          Change
        </Button>
      )
    );
  }

  const renderJoinType = (user) => {
    return (
      user.joinType === "WITH_TICKETS" ? ("With tickets") : "Without tickets"
    );
  }

  const renderFT = (user) => {
    return (
      <>
        {user.eligibleForFastTrack ? "Yes" : "No"}
      </>
    );
  }

  const renderActions = (user) => {
    return (
      <Button
        variant="text"
        size="small"
        type="button"
        style={{color: '#0070f0', marginRight: 10}}
        onClick={() => history.push('/users/' + user.id + '/edit')}
      >
        Edit
      </Button>
    )
  }

  const refund = async (e) => {
    e.preventDefault();
    try {
      handleCloseModalRefund();
      await client.refundUser(user.id);
      setUser({...user, status: "REFUNDED"});
      notification.success("Refunded");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalRefund();
    }
  }

  const changeJoinType = async (e) => {
    e.preventDefault();
    try {
      await client.changeJoinType(user.id);
      handleCloseModalChangeJoinType();
      setUser({...user, joinType: "WITH_TICKETS"});
      notification.success("Join type changed");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalChangeJoinType();
    }
  }

  const modalBodyChangeJoinType = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Change join type to "With tickets"</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={changeJoinType}>
        <div className={'modalBody'}>
          After confirm, tickets for 52 weeks will be created with game type {user && user.gameType}
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalChangeJoinType}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );

  const moveVct = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amount.value.replace(',', '.'));
    try {
      await client.moveVCT({
        fromUser: user.id,
        toUser: e.target.toUser.value,
        amount: amount
      });
      handleCloseModalMoveVct();
      const newAmount = wallet.vctBalance - amount;
      setWallet({...wallet, vctBalance: newAmount});
      notification.success("VCT transferred");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalMoveVct();
    }
  }

  const modalBodyMoveVct = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Move VCT</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={moveVct}>
        <div className={'modalBody'}>
          <TextField
            id="amount"
            name="amount"
            type="number"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="toUser"
            name="toUser"
            type="number"
            label="toUser ID"
            placeholder="toUser ID"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalMoveVct}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );

  const modalBodyRefund = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Refund</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={refund}>
        <div className={'modalBody'}>
          After confirm, user status will be "refunded". All bonuses will be rolled back. Tickets will be
          canceled. <bt/>
          You will not be able to cancel this operation after confirmation.
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalRefund}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );


  const topupCash = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amountCash.value.replace(',', '.'));
    const reason = e.target.reason.value;
    try {
      await client.topupWallet({
        userId: id,
        amount: amount,
        balanceType: "CASH",
        reason: reason
      })
      handleCloseModalTopupCash();
      const newAmount = wallet.cashBalance + amount;
      setWallet({...wallet, cashBalance: newAmount});
      notification.success("€" + amount + " added to cash wallet");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalTopupCash();
    }
  }


  const modalBodyTopupCash = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Top up cash wallet</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={topupCash}>
        <div className={'modalBody'}>
          <TextField
            id="amountCash"
            name="amountCash"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="reason"
            name="reason"
            type="text"
            label="Note"
            placeholder="Note"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalTopupCash}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );

  const topupTrading = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amountTrading.value.replace(',', '.'));
    const reason = e.target.reason.value;
    try {
      await client.topupWallet({
        userId: id,
        amount: amount,
        balanceType: "TRADING",
        reason: reason
      })
      handleCloseModalTopupTrading();
      const newAmount = wallet.tradingBalance + amount;
      setWallet({...wallet, tradingBalance: newAmount});
      notification.success("€" + amount + " added to trading wallet");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalTopupTrading();
    }
  }

  const modalBodyTopupTrading = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Top up trading wallet</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={topupTrading}>
        <div className={'modalBody'}>
          <TextField
            id="amountTrading"
            name="amountTrading"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="reason"
            name="reason"
            type="text"
            label="Note"
            placeholder="Note"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalTopupTrading}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );


  const topupPpc = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amountPpc.value.replace(',', '.'));
    const reason = e.target.reason.value;
    try {
      await client.topupWallet({
        userId: id,
        amount: amount,
        balanceType: "PPC",
        reason: reason
      })
      handleCloseModalTopupPpc();
      const newAmount = wallet.ppcBalance + amount;
      setWallet({...wallet, ppcBalance: newAmount});
      notification.success(amount + " PPC added");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalTopupPpc();
    }
  }

  const modalBodyTopupPpc = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Top up PPC wallet</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={topupPpc}>
        <div className={'modalBody'}>
          <TextField
            id="amountPpc"
            name="amountPpc"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="reason"
            name="reason"
            type="text"
            label="Note"
            placeholder="Note"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalTopupPpc}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );

  const topupVct = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amountVct.value.replace(',', '.'));
    const reason = e.target.reason.value;
    try {
      await client.topupWallet({
        userId: id,
        amount: amount,
        balanceType: "VCT",
        reason: reason
      })
      handleCloseModalTopupVct();
      const newAmount = wallet.vctBalance + amount;
      setWallet({...wallet, vctBalance: newAmount});
      notification.success(amount + " VCT added");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalTopupVct();
    }
  }

  const modalBodyTopupVct = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Top up VCT wallet</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={topupVct}>
        <div className={'modalBody'}>
          <TextField
            id="amountVct"
            name="amountVct"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="reason"
            name="reason"
            type="text"
            label="Note"
            placeholder="Note"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalTopupVct}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );

  const topupCgt = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amountCgt.value.replace(',', '.'));
    const reason = e.target.reason.value;
    try {
      await client.topupWallet({
        userId: id,
        amount: amount,
        balanceType: "CGT",
        reason: reason
      })
      handleCloseModalTopupCgt();
      const newAmount = wallet.cgtBalance + amount;
      setWallet({...wallet, cgtBalance: newAmount});
      notification.success(amount + " CGT added");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalTopupCgt();
    }
  }

  const modalBodyTopupCgt = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Top up CGT wallet</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={topupCgt}>
        <div className={'modalBody'}>
          <TextField
            id="amountCgt"
            name="amountCgt"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="reason"
            name="reason"
            type="text"
            label="Note"
            placeholder="Note"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalTopupCgt}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );


  const withdrawCash = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amountCashWithdraw.value.replace(',', '.'));
    const reason = e.target.reason.value;
    try {
      await client.withdrawFromWallet({
        userId: id,
        amount: amount,
        balanceType: "CASH",
        reason: reason
      })
      handleCloseModalWithdrawCash();
      const newAmount = wallet.cashBalance - amount;
      setWallet({...wallet, cashBalance: newAmount});
      notification.success("€" + amount + " withdrawn from cash wallet");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalWithdrawCash();
    }
  }

  const modalBodyWithdrawCash = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Withdraw from cash wallet</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={withdrawCash}>
        <div className={'modalBody'}>
          <TextField
            id="amountCashWithdraw"
            name="amountCashWithdraw"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="reason"
            name="reason"
            type="text"
            label="Note"
            placeholder="Note"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalWithdrawCash}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );

  const withdrawTrading = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amountTradingWithdraw.value.replace(',', '.'));
    const reason = e.target.reason.value;
    try {
      await client.withdrawFromWallet({
        userId: id,
        amount: amount,
        balanceType: "TRADING",
        reason: reason
      })
      handleCloseModalWithdrawTrading();
      const newAmount = wallet.tradingBalance - amount;
      setWallet({...wallet, tradingBalance: newAmount});
      notification.success("€" + amount + " withdrawn from trading wallet");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalWithdrawTrading();
    }
  }

  const modalBodyWithdrawTrading = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Withdraw from trading wallet</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={withdrawTrading}>
        <div className={'modalBody'}>
          <TextField
            id="amountTradingWithdraw"
            name="amountTradingWithdraw"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="reason"
            name="reason"
            type="text"
            label="Note"
            placeholder="Note"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalWithdrawTrading}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );

  const withdrawPpc = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amountPpcWithdraw.value.replace(',', '.'));
    const reason = e.target.reason.value;
    try {
      await client.withdrawFromWallet({
        userId: id,
        amount: amount,
        balanceType: "PPC",
        reason: reason
      })
      handleCloseModalWithdrawPpc();
      const newAmount = wallet.ppcBalance - amount;
      setWallet({...wallet, ppcBalance: newAmount});
      notification.success(amount + " PPC withdrawn from wallet");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalWithdrawPpc();
    }
  }

  const modalBodyWithdrawPpc = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Withdraw from PPC wallet</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={withdrawPpc}>
        <div className={'modalBody'}>
          <TextField
            id="amountPpcWithdraw"
            name="amountPpcWithdraw"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="reason"
            name="reason"
            type="text"
            label="Note"
            placeholder="Note"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalWithdrawPpc}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );

  const withdrawVct = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amountVctWithdraw.value.replace(',', '.'));
    const reason = e.target.reason.value;
    try {
      await client.withdrawFromWallet({
        userId: id,
        amount: amount,
        balanceType: "VCT",
        reason: reason
      })
      handleCloseModalWithdrawVct();
      const newAmount = wallet.vctBalance - amount;
      setWallet({...wallet, vctBalance: newAmount});
      notification.success(amount + " VCT withdrawn from wallet");
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModalWithdrawVct();
    }
  }

  const modalBodyWithdrawVct = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Withdraw from VCT wallet</h2>
      <p id="simple-modal-description">
      </p>
      <form onSubmit={withdrawVct}>
        <div className={'modalBody'}>
          <TextField
            id="amountVctWithdraw"
            name="amountVctWithdraw"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="reason"
            name="reason"
            type="text"
            label="Note"
            placeholder="Note"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalWithdrawVct}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );


  const getUser = useCallback(async () => {
    try {
      const userResponse = await client.getUserById(id);
      setUser(userResponse);

      const walletResponse = await client.getWalletById(userResponse.walletId);
      setWallet(walletResponse);

      const statResponse = await client.getUserOrganisationStat(id);
      setStat(statResponse);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [id]);

  const getLastLogin = useCallback(async () => {
    const response = await client.getLastLogin(id);
    setLastLogin(response);
  }, [id]);

  useEffect(() => {
    getUser();
    getLastLogin()
  }, [getUser, getLastLogin, id]);


  const [headerLoginHistoryFields, setHeaderLoginHistoryFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'ipAddress', value: 'IP address', direction: 'asc', sorted: true},
    {name: 'country', value: 'Country', direction: 'asc', sorted: false},
    {name: 'city', value: 'City', direction: 'asc', sorted: false},
    {name: 'device', value: 'Device', direction: 'asc', sorted: false},
    {name: 'os', value: 'OS', direction: 'asc', sorted: false},
    {name: 'browser', value: 'Browser', direction: 'asc', sorted: false},
    {name: 'createdAt', value: 'Date', direction: 'asc', sorted: false},
  ])

  const getLoginHistory = useCallback(async () => {
    try {
      const response = await client.getLoginHistory({
        page: currentLoginHistoryPage,
        size: rowsPerLoginHistoryPage,
        userId: id
      })
      setLoginHistory(response.content);
      setTotalLoginHistory(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentLoginHistoryPage, rowsPerLoginHistoryPage, id]);

  useEffect(() => {
    getLoginHistory();
  }, [currentLoginHistoryPage, id]);

  const onChangeLoginHistoryPage = (_, newPage) => {
    setCurrentLoginHistoryPage(newPage)
  }

  const handleChangeRowsPerLoginHistoryPage = (e) => {
    setRowsPerLoginHistoryPage(e.target.value);
    setCurrentLoginHistoryPage(0);
  };


  const [headerFirstLineFields, setHeaderFirstLineFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'username', value: 'Username', direction: 'asc', sorted: false},
    {name: 'refId', value: 'Ref', direction: 'asc', sorted: false},
    {name: 'firstName', value: 'First name', direction: 'asc', sorted: false},
    {name: 'lastName', value: 'Last name', direction: 'asc', sorted: false},
    {name: 'email', value: 'Email', direction: 'asc', sorted: false},
    {name: 'status', value: 'Status', direction: 'asc', sorted: false},
    {name: 'eligibleForFastTrack', value: 'FT', direction: 'asc', sorted: false},
    {name: 'gameType', value: 'Game', direction: 'asc', sorted: false},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
    {name: 'memberSince', value: 'Member Since', direction: 'asc', sorted: false},
    {name: 'Actions', value: 'Actions', direction: 'asc', sorted: false},
  ])

  const getFirstLine = useCallback(async () => {
    try {
      const response = await client.getAllUsers({
        page: currentFirstLinePage,
        size: rowsPerFirstLinePage,
        referral: id
      })
      setFirstLine(response.content);
      setTotalFirstLine(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentFirstLinePage, rowsPerFirstLinePage, id]);

  useEffect(() => {
    getFirstLine();
  }, [currentFirstLinePage, id]);

  const onChangeFirstLinePage = (_, newPage) => {
    setCurrentFirstLinePage(newPage)
  }

  const handleChangeRowsPerFirstLinePage = (e) => {
    setRowsPerFirstLinePage(e.target.value);
    setCurrentFirstLinePage(0);
  };

  const [headerVouchersFields, setHeaderVouchersFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'userId', value: 'User', direction: 'asc', sorted: false},
    {name: 'activatedBy', value: 'Activated by', direction: 'asc', sorted: false},
    {name: 'orderId', value: 'Order', direction: 'asc', sorted: false},
    {name: 'code', value: 'Code', direction: 'asc', sorted: false},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: false},
    {name: 'status', value: 'Status', direction: 'asc', sorted: false},
    {name: 'type', value: 'Type', direction: 'asc', sorted: false},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
  ])

  const getVouchers = useCallback(async () => {
    try {
      const response = await client.getAllVouchers({
        page: currentVouchersPage,
        size: rowsPerVouchersPage,
        userId: id
      })
      setVouchers(response.content);
      setTotalVouchers(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentVouchersPage, rowsPerVouchersPage, id]);

  useEffect(() => {
    getVouchers();
  }, [currentVouchersPage, id]);

  const onChangeVouchersPage = (_, newPage) => {
    setCurrentVouchersPage(newPage)
  }

  const handleChangeRowsPerVouchersPage = (e) => {
    setRowsPerVouchersPage(e.target.value);
    setCurrentVouchersPage(0);
  };

  const [headerTicketsFields, setHeaderTicketsFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'userId', value: 'User', direction: 'asc', sorted: false},
    {name: 'gameType', value: 'Game', direction: 'asc', sorted: false},
    {name: 'numbers', value: 'Numbers', direction: 'asc', sorted: false},
    {name: 'week', value: 'Week', direction: 'asc', sorted: false},
    {name: 'status', value: 'Status', direction: 'asc', sorted: false},
    {name: 'sent', value: 'Sent', direction: 'asc', sorted: false},
    {name: 'created', value: 'Created', direction: 'asc', sorted: false},
    {name: 'startedAt', value: 'Started', direction: 'asc', sorted: false},
  ])

  const getTickets = useCallback(async () => {
    try {
      const response = await client.getAllTickets({
        page: currentTicketsPage,
        size: rowsPerTicketsPage,
        userId: id
      })
      setTickets(response.content);
      setTotalTickets(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentTicketsPage, rowsPerTicketsPage, id]);

  useEffect(() => {
    getTickets();
  }, [currentTicketsPage, id]);

  const onChangeTicketsPage = (_, newPage) => {
    setCurrentTicketsPage(newPage)
  }

  const handleChangeRowsPerTicketsPage = (e) => {
    setRowsPerTicketsPage(e.target.value);
    setCurrentTicketsPage(0);
  };

  useEffect(() => {
    if (user) {
      reset({
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        email: user.email,
        dateOfBirth: user.dateOfBirth,
        phone: user.phone,
        country: user.countryId,
        refId: user.refId,
        gameType: user.gameType,
        role: user.role,
      })
    }
  }, [user, id]);


  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };


  const renderTicketActions = (ticket) => {
    return (
      <>

        <Tooltip title="Manual win" aria-label="manual win">
          <EmojiEvents className="actionIcon" onClick={() => handleOpenModalWin(ticket.id)}/>
        </Tooltip>

      </>
    )
  }

  const manualWin = async (e) => {
    e.preventDefault();
    const amount = +(e.target.amount.value.replace(',', '.'));
    try {
      await client.payManualWin({
        userId: user && user.id,
        ticketId: ticketId && ticketId,
        date: e.target.date.value,
        amount: amount,
      });
      handleCloseModalWin();
      notification.success("Manual win done")
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const modalBodyWin = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Manual win</h2>
      <p id="simple-modal-description">
        Make a manual win for user ID: {user && user.id}, ticket ID: {ticketId && ticketId}. <br/>
        Be careful, the system does not do any validations and limits for tickets. Make sure you do it for the right
        user and ticket and do it only one time. After manual payment the manual win icon still will be available.
      </p>
      <form onSubmit={manualWin}>
        <div className={'modalBody'}>
          <TextField
            id="amount"
            name="amount"
            type="text"
            label="Amount"
            placeholder="Amount"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            name="date"
            type="text"
            label="Date"
            placeholder="YYYY-MM-DD"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={'buttonContainer'}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseModalWin}
          >
            Cancel
          </Button>

        </div>
      </form>
    </div>
  );


  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
        >
          <Tab label="All" component={Link} to={'/users'}/>
          <Tab label="Edit" component={Link} to={'/users/' + id + '/edit'}/>
        </Tabs>
      </Paper>

      <div className="content">
        <Paper square className="flexPaper" style={{marginBottom: 10}}>
          <div className="flexDiv">
            <div className="margin0">Members information</div>
            <div className="colorGray">Internal UID: {user && user.id}</div>
            <div className="colorGray">External UID: {user && user.internalId}</div>
            <div className="colorGray">Registration date: {user && user.createdAt}</div>
          </div>
        </Paper>
        <div className="flexContainer">
          <Paper square className="flexPaper profileInfo">
            <div style={{width: "100%"}}>
              <div className="paperCaption">Profile info</div>
              <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}} className="profileInfoForm">
                <div className="marginBottom25inside profileInfoFormDiv">
                  <div>
                    <Controller control={control} as={<TextField
                      id="firstName"
                      name="firstName"
                      type="text"
                      label="First name"
                      placeholder="First name"
                      autoComplete="off"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />} name="firstName"/>
                  </div>
                  <div>
                    <Controller control={control} as={<TextField
                      id="lastName"
                      name="lastName"
                      type="text"
                      label="Last name"
                      placeholder="Last name"
                      autoComplete="off"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />} name="lastName"/>
                  </div>
                  <div>
                    <Controller control={control} as={<TextField
                      id="username"
                      name="username"
                      type="text"
                      label="Username"
                      placeholder="Username"
                      autoComplete="off"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />} name="username"/>
                  </div>
                  <div>
                    <Controller control={control} as={<TextField
                      id="email"
                      name="email"
                      type="email"
                      label="Email"
                      placeholder="Email"
                      autoComplete="off"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />} name="email"/>
                  </div>
                  <div>
                    <Controller control={control} as={<TextField
                      id="dateOfBirth"
                      name="dateOfBirth"
                      type="text"
                      label="Date of birth"
                      placeholder="Date of birth"
                      autoComplete="off"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />} name="dateOfBirth"/>
                  </div>
                </div>
                <div className="marginBottom25inside profileInfoFormDiv">
                  <div>
                    <Controller control={control} as={<TextField
                      id="phone"
                      name="phone"
                      type="text"
                      label="Phone"
                      placeholder="Phone"
                      autoComplete="off"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />} name="phone"/>
                  </div>
                  <div>
                    <FormHelperText>Country</FormHelperText>
                    <Controller control={control} defaultValue={1} as={
                      <Select
                        id="country"
                        name="country"
                        fullWidth={true}
                      >
                        {countryList.map((item, index) => (
                            <MenuItem key={index} value={+item.value}>{item.text}</MenuItem>
                          )
                        )}
                      </Select>
                    } name="country"/>
                  </div>
                  <div>
                    <Controller control={control} as={<TextField
                      id="refId"
                      name="refId"
                      type="text"
                      label="Referral"
                      placeholder="Referral"
                      autoComplete="off"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />} name="refId"/>
                  </div>
                  <div>
                    <FormHelperText>Game type</FormHelperText>
                    <Controller control={control} defaultValue={"game type"} as={
                      <Select
                        id="gameType"
                        name="gameType"
                        fullWidth={true}
                      >
                        <MenuItem key="1" value={"LITE"}>Lite</MenuItem>
                        <MenuItem key="2" value={"MEGA"}>Mega</MenuItem>
                        <MenuItem key="3" value={"GIGA"}>Giga</MenuItem>
                      </Select>} name="gameType"/>
                  </div>
                  <div>
                    <FormHelperText>Role</FormHelperText>
                    <Controller control={control} defaultValue={"Role"} as={
                      <Select
                        id="role"
                        name="role"
                        label="dsfsf"
                        placeholder="dsfsf"
                        fullWidth={true}
                      >
                        <MenuItem key="1" value={"ROLE_CLIENT"}>Client (User)</MenuItem>
                        <MenuItem key="1" value={"ROLE_ADMIN"}>Admin</MenuItem>
                        <MenuItem key="1" value={"ROLE_MODERATOR"}>Moderator</MenuItem>
                        <MenuItem key="1" value={"ROLE_TRANSLATOR"}>Translator</MenuItem>
                      </Select>} name="role"/>
                  </div>
                </div>
                <div className="formButton">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    type="submit"
                  >
                    Update
                  </Button>
                </div>
              </form>
            </div>
          </Paper>
          <Paper square className="flexPaper walletInfo">
            <div>
              <div className="paperCaption">Wallet info</div>
              <div>
                <table className="table">
                  <thead>
                  <th/>
                  <th>Available</th>
                  <th>Hold</th>
                  <th>Actions</th>
                  </thead>
                  <tbody>
                  <tr>
                    <td className="colorGray">Cash balance:</td>
                    <td style={{textAlign: "right"}}>€{wallet && wallet.cashBalance}</td>
                    <td style={{textAlign: "right"}}>€{wallet && wallet.cashHold}</td>
                    <td style={{textAlign: "right"}}>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="actionButtons"
                        onClick={handleOpenModalTopupCash}
                      >
                        Top up
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="actionButtons"
                        onClick={handleOpenModalWithdrawCash}
                      >
                        Withdraw
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className="colorGray">Trading balance:</td>
                    <td style={{textAlign: "right"}}>€{wallet && wallet.tradingBalance}</td>
                    <td style={{textAlign: "right"}}>€{wallet && wallet.tradingHold}</td>
                    <td style={{textAlign: "right"}}>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="actionButtons"
                        onClick={handleOpenModalTopupTrading}
                      >
                        Top up
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="actionButtons"
                        onClick={handleOpenModalWithdrawTrading}
                      >
                        Withdraw
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className="colorGray">PPC balance:</td>
                    <td
                      style={{textAlign: "right"}}>{wallet && (wallet.ppcBalance > -1 ? wallet.ppcBalance : "Not connected")}</td>
                    <td style={{textAlign: "right"}}>{wallet && wallet.ppcHold}</td>
                    <td style={{textAlign: "right"}}>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="actionButtons"
                        onClick={handleOpenModalTopupPpc}
                      >
                        Top up
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="actionButtons"
                        onClick={handleOpenModalWithdrawPpc}
                      >
                        Withdraw
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className="colorGray">CGT balance:</td>
                    <td
                      style={{textAlign: "right"}}>{wallet && (wallet.cgtBalance > -1 ? wallet.cgtBalance : "Not connected")}</td>
                    <td style={{textAlign: "right"}}>{wallet && wallet.cgtHold}</td>
                    <td style={{textAlign: "right"}}>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="actionButtons"
                        onClick={handleOpenModalTopupCgt}
                      >
                        Top up
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className="colorGray">VCT balance:</td>
                    <td
                      style={{textAlign: "right"}}>{wallet && (wallet.vctBalance > -1 ? wallet.vctBalance : "Not connected")}</td>
                    <td style={{textAlign: "right"}}>{wallet && wallet.vctHold}</td>
                    <td style={{textAlign: "right"}}>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="actionButtons"
                        onClick={handleOpenModalTopupVct}
                      >
                        Top up
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        className="actionButtons"
                        onClick={handleOpenModalWithdrawVct}
                      >
                        Withdraw
                      </Button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table className="table" style={{marginTop: 20}}>
                  <tbody>
                  <tr>
                    <td className="colorGray">BTC address:</td>
                    <td>{wallet && wallet.btcAddress}</td>
                  </tr>
                  <tr>
                    <td className="colorGray">USDT address:</td>
                    <td>{wallet && wallet.usdtAddress}</td>
                  </tr>
                  <tr>
                    <td className="colorGray">PPC address:</td>
                    <td>{wallet && wallet.ppcAddress}</td>
                  </tr>
                  <tr>
                    <td className="colorGray">CGT address:</td>
                    <td>{wallet && wallet.cgtAddress}</td>
                  </tr>
                  <tr>
                    <td className="colorGray">VCT address:</td>
                    <td>{wallet && wallet.vctAddress}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Paper>
        </div>

        <div className="flexContainer">
          <Paper square className="flexPaper userSettings">
            <div>
              <div className="paperCaption">User data</div>
              <table className="table">
                <tbody>
                <tr>
                  <td>Status:</td>
                  <td>{user && renderStatus(user)}</td>
                </tr>
                <tr>
                  <td>KYC:</td>
                  <td>{user && user.kycStatus}</td>
                </tr>
                <tr>
                  <td>Join type:</td>
                  <td>{user && renderJoinType(user)} {user && renderChangeJoinTypeButton(user)}</td>
                </tr>
                <tr>
                  <td>Team player:</td>
                  <td>{user && user.userType}</td>
                </tr>
                <tr>
                  <td>Title:</td>
                  <td>{user && user.userTitle}</td>
                </tr>
                <tr>
                  <td>Qualified for FT:</td>
                  <td>{user && user.eligibleForFastTrack ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Member since:</td>
                  <td>{user && user.memberSince}</td>
                </tr>
                <tr>
                  <td/>
                  <td/>
                </tr>
                <tr>
                  <td/>
                  <td/>
                </tr>
                <tr>
                  <td>Invited lite:</td>
                  <td>{stat && stat.invitedLite}</td>
                </tr>
                <tr>
                  <td>Invited mega:</td>
                  <td>{stat && stat.invitedMega}</td>
                </tr>
                <tr>
                  <td>Invited giga:</td>
                  <td>{stat && stat.invitedGiga}</td>
                </tr>
                <tr>
                  <td>Gold legs:</td>
                  <td>{stat && stat.goldLegs}</td>
                </tr>
                <tr>
                  <td>Platinum legs:</td>
                  <td>{stat && stat.platinumLegs}</td>
                </tr>
                <tr>
                  <td>Sapphire legs:</td>
                  <td>{stat && stat.sapphireLegs}</td>
                </tr>
                <tr>
                  <td>Ruby or higher legs:</td>
                  <td>{stat && stat.rubyOrHigherLegs}</td>
                </tr>
                <tr>
                  <td>Total sales:</td>
                  <td>{stat && stat.totalSales && stat.totalSales.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</td>
                </tr>
                <tr>
                  <td/>
                  <td/>
                </tr>
                <tr>
                  <td/>
                  <td/>
                </tr>
                <tr>
                  <td>CB cash:</td>
                  <td>{user && user.earned && user.earned.earnedTotalCbCash.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</td>
                </tr>
                <tr>
                  <td>CB trading:</td>
                  <td>{user && user.earned && user.earned.earnedTotalCbTrading.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</td>
                </tr>
                  <tr>
                  <td>Total pool bonus:</td>
                  <td>{user && user.earned && user.earned.earnedTotalPoolBonus.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</td>
                </tr>
                <tr>
                  <td>Total percentage pool bonus:</td>
                  <td>{user && user.earned && user.earned.earnedTotalPercentagePoolBonus.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </Paper>

          <div className="lastLoginInfo">
            <Paper square className="flexPaper" style={{marginBottom: 10}}>
              <div>
                <div className="paperCaption">Actions</div>
                <div className="flexDiv">
                  <Button
                    variant="contained"
                    size="small"
                    type="submit"
                    className="actionButtons"
                    onClick={handleOpenModalRefund}
                  >
                    Refund
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    type="submit"
                    className="actionButtons"
                    onClick={handleOpenModalMoveVct}
                  >
                    Move VCT
                  </Button>
                </div>
              </div>
            </Paper>
            <Paper square className="flexPaper">
              <div>
                <div className="paperCaption">Last login info</div>
                <table className="table">
                  <tbody>
                  <tr>
                    <td>Date:</td>
                    <td>{lastLogin && lastLogin.createdAt}</td>
                  </tr>
                  <tr>
                    <td>IP address:</td>
                    <td>{lastLogin && lastLogin.ipAddress}</td>
                  </tr>
                  <tr>
                    <td>Country:</td>
                    <td>{lastLogin && lastLogin.countryName}</td>
                  </tr>
                  <tr>
                    <td>City:</td>
                    <td>{lastLogin && lastLogin.city}</td>
                  </tr>
                  <tr>
                    <td>Device:</td>
                    <td>{lastLogin && lastLogin.device}</td>
                  </tr>
                  <tr>
                    <td>OS:</td>
                    <td>{lastLogin && lastLogin.os}</td>
                  </tr>
                  <tr>
                    <td>Browser:</td>
                    <td>{lastLogin && lastLogin.browser}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
          </div>
        </div>

        <div className="caption">User transactions</div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerTransactionsFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions && transactions.map((transaction, index) => (
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                  <TableCell>{transaction.id}</TableCell>
                  <TableCell>{renderUserLink(transaction.userId)}</TableCell>
                  <TableCell>{renderUserLink(transaction.initUserId)}</TableCell>
                  <TableCell>{transaction.amountOriginal.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{transaction.amountCash.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{transaction.amountTrading.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{transaction.amountPpc}</TableCell>
                  <TableCell>{transaction.type}</TableCell>
                  <TableCell>{transaction.ioType}</TableCell>
                  <TableCell>{transaction.reason}</TableCell>
                  <TableCell>{transaction.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalTransactions}
            rowsPerPage={rowsPerTransactionsPage}
            page={currentTransactionsPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={onChangeTransactionsPage}
            onChangeRowsPerPage={handleChangeRowsPerTransactionsPage}
          />
        </TableContainer>

        <div className="caption">User exchange orders</div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerExchangeOrdersFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {exchangeOrders && exchangeOrders.map((exchangeOrder, index) => (
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                  <TableCell>{exchangeOrder.id}</TableCell>
                  <TableCell>{renderUserLink(exchangeOrder.userId)}</TableCell>
                  <TableCell>{renderUserLink(exchangeOrder.executorUserId)}</TableCell>
                  <TableCell>{exchangeOrder.amount}</TableCell>
                  <TableCell>{exchangeOrder.price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{exchangeOrder.type}</TableCell>
                  <TableCell>{exchangeOrder.status}</TableCell>
                  <TableCell>{exchangeOrder.createdAt}</TableCell>
                  <TableCell>{exchangeOrder.executedAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalExchangeOrders}
            rowsPerPage={rowsPerExchangeOrdersPage}
            page={currentExchangeOrdersPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={onChangeExchangeOrdersPage}
            onChangeRowsPerPage={handleChangeRowsPerExchangeOrdersPage}
          />
        </TableContainer>


        <div className="caption">User login history</div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerLoginHistoryFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loginHistory && loginHistory.map((loginHistory, index) => (
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                  <TableCell>{loginHistory.id}</TableCell>
                  <TableCell>{loginHistory.ipAddress}</TableCell>
                  <TableCell>{loginHistory.countryName}</TableCell>
                  <TableCell>{loginHistory.city}</TableCell>
                  <TableCell>{loginHistory.device}</TableCell>
                  <TableCell>{loginHistory.os}</TableCell>
                  <TableCell>{loginHistory.browser}</TableCell>
                  <TableCell>{loginHistory.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalLoginHistory}
            rowsPerPage={rowsPerLoginHistoryPage}
            page={currentLoginHistoryPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={onChangeLoginHistoryPage}
            onChangeRowsPerPage={handleChangeRowsPerLoginHistoryPage}
          />
        </TableContainer>


        <div className="caption">First line users</div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerFirstLineFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {firstLine && firstLine.map((firstLine, index) => (
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                  <TableCell>{firstLine.id}</TableCell>
                  <TableCell>{firstLine.username}</TableCell>
                  <TableCell>{renderUserLink(firstLine.refId)}</TableCell>
                  <TableCell>{firstLine.firstName}</TableCell>
                  <TableCell>{firstLine.lastName}</TableCell>
                  <TableCell>{firstLine.email}</TableCell>
                  <TableCell>{renderStatus(firstLine)}</TableCell>
                  <TableCell>{renderFT(firstLine)}</TableCell>
                  <TableCell>{firstLine.gameType}</TableCell>
                  <TableCell>{firstLine.createdAt}</TableCell>
                  <TableCell>{firstLine.memberSince}</TableCell>
                  <TableCell>{renderActions(firstLine)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalFirstLine}
            rowsPerPage={rowsPerFirstLinePage}
            page={currentFirstLinePage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={onChangeFirstLinePage}
            onChangeRowsPerPage={handleChangeRowsPerFirstLinePage}
          />
        </TableContainer>


        <div className="caption">User vouchers</div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerVouchersFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {vouchers && vouchers.map((voucher, index) => (
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                  <TableCell>{voucher.id}</TableCell>
                  <TableCell>{renderUserLink(voucher.userId)}</TableCell>
                  <TableCell>{renderUserLink(voucher.activatedBy)}</TableCell>
                  <TableCell>{voucher.orderId}</TableCell>
                  <TableCell>{voucher.code}</TableCell>
                  <TableCell>{voucher.amount}</TableCell>
                  <TableCell>{voucher.status}</TableCell>
                  <TableCell>{voucher.type}</TableCell>
                  <TableCell>{voucher.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalVouchers}
            rowsPerPage={rowsPerVouchersPage}
            page={currentVouchersPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={onChangeVouchersPage}
            onChangeRowsPerPage={handleChangeRowsPerVouchersPage}
          />
        </TableContainer>

        <div className="caption">User orders</div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerOrdersFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders && orders.map((order, index) => (
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>{order.amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{order.cryptoAmount}</TableCell>
                  <TableCell>{order.cryptoAddress}</TableCell>
                  <TableCell>{order.fixedCryptoRate && order.fixedCryptoRate.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{order.paymentMethod}</TableCell>
                  <TableCell>{order.status}</TableCell>
                  <TableCell>{order.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalExchangeOrders}
            rowsPerPage={rowsPerExchangeOrdersPage}
            page={currentExchangeOrdersPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={onChangeExchangeOrdersPage}
            onChangeRowsPerPage={handleChangeRowsPerExchangeOrdersPage}
          />
        </TableContainer>



        <div className="caption">User tickets</div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerTicketsFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets && tickets.map((ticket, index) => (
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                  <TableCell>{ticket.id}</TableCell>
                  <TableCell>{renderUserLink(ticket.userId)}</TableCell>
                  <TableCell>{ticket.gameType}</TableCell>
                  <TableCell>{ticket.numbers}</TableCell>
                  <TableCell>{ticket.week}</TableCell>
                  <TableCell>{ticket.status}</TableCell>
                  <TableCell>{ticket.sent}</TableCell>
                  <TableCell>{ticket.createdAt}</TableCell>
                  <TableCell>{ticket.startAt}</TableCell>
                  <TableCell>{renderTicketActions(ticket)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalTickets}
            rowsPerPage={rowsPerTicketsPage}
            page={currentTicketsPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={onChangeTicketsPage}
            onChangeRowsPerPage={handleChangeRowsPerTicketsPage}
          />
        </TableContainer>
      </div>

      <Modal
        open={openModalTopupCash}
        onClose={handleCloseModalTopupCash}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyTopupCash}
      </Modal>

      <Modal
        open={openModalTopupTrading}
        onClose={handleCloseModalTopupTrading}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyTopupTrading}
      </Modal>

      <Modal
        open={openModalTopupPpc}
        onClose={handleCloseModalTopupPpc}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyTopupPpc}
      </Modal>

      <Modal
        open={openModalTopupVct}
        onClose={handleCloseModalTopupVct}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyTopupVct}
      </Modal>

      <Modal
        open={openModalTopupCgt}
        onClose={handleCloseModalTopupCgt}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyTopupCgt}
      </Modal>

      <Modal
        open={openModalWithdrawCash}
        onClose={handleCloseModalWithdrawCash}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyWithdrawCash}
      </Modal>

      <Modal
        open={openModalWithdrawTrading}
        onClose={handleCloseModalWithdrawTrading}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyWithdrawTrading}
      </Modal>

      <Modal
        open={openModalWithdrawPpc}
        onClose={handleCloseModalWithdrawPpc}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyWithdrawPpc}
      </Modal>

      <Modal
        open={openModalWithdrawVct}
        onClose={handleCloseModalWithdrawVct}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyWithdrawVct}
      </Modal>

      <Modal
        open={openModalChangeJoinType}
        onClose={handleCloseModalChangeJoinType}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyChangeJoinType}
      </Modal>

      <Modal
        open={openModalRefund}
        onClose={handleCloseModalRefund}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyRefund}
      </Modal>

      <Modal
        open={openModalMoveVct}
        onClose={handleCloseModalMoveVct}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyMoveVct}
      </Modal>

      <Modal
        open={openModalWin}
        onClose={handleCloseModalWin}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyWin}
      </Modal>

    </>
  )
}
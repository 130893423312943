import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab
} from "@material-ui/core";
import {Link, useHistory, useParams} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";

export const TransactionsEdit = () => {
  const [value, setValue] = useState(1);
  const [transaction, setTransaction] = useState();
  const history = useHistory();
  const { id } = useParams()

  const getTransaction = useCallback(async () => {
    try {
      console.log(id)
      const response = await client.getTransactionById(id);
      setTransaction(response);
    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, []);

  useEffect(() => {
    getTransaction();
  }, [getTransaction]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/transactions'}/>
          <Tab label="Edit" component={Link} to={'/transactions/' + id + '/edit'}/>
        </Tabs>
      </Paper>
    </>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab, Modal, FormControl, FormHelperText, Card, CardContent, Typography, CircularProgress
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {getFormattedDate, searchParamsToObject, toEur} from "../../utils/tools";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const Pool = () => {
  const [value, setValue] = useState(0);
  const [poolStat, setPoolStat] = useState();
  const history = useHistory();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date("2021-12-01"));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'userId', value: 'UID', direction: 'asc', sorted: true},
    {name: 'title', value: 'Rank', direction: 'asc', sorted: true},
    {name: 'ppq', value: 'PPQ', direction: 'asc', sorted: true},
    {name: 'mainPool', value: 'Main Pool', direction: 'asc', sorted: true},
    {name: 'crownAmbassador', value: 'CA', direction: 'asc', sorted: true},
    {name: 'grandAmbassador', value: 'GA', direction: 'asc', sorted: true},
    {name: 'ambassador', value: 'A', direction: 'asc', sorted: true},
    {name: 'crownDiamond', value: 'CD', direction: 'asc', sorted: true},
    {name: 'blackDiamond', value: 'BD', direction: 'asc', sorted: true},
    {name: 'blueDiamond', value: 'BD', direction: 'asc', sorted: true},
    {name: 'diamond', value: 'D', direction: 'asc', sorted: true},
    {name: 'emerald', value: 'E', direction: 'asc', sorted: true},
    {name: 'ruby', value: 'R', direction: 'asc', sorted: true},
    {name: 'sapphire', value: 'S', direction: 'asc', sorted: true},
    {name: 'platinum', value: 'P', direction: 'asc', sorted: true},
    {name: 'gold', value: 'G', direction: 'asc', sorted: true},
    {name: 'total', value: 'Total', direction: 'asc', sorted: true},
  ])

  const getPoolStat = useCallback(async () => {
    try {
      console.log(selectedStartDate);
      const _params = new URLSearchParams(history.location.search);
      const response = await client.getPoolStat(searchParamsToObject(_params));
      setPoolStat(response)
    } catch (error) {
      notification.warning(error.message);
    }
  }, [history]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    if (startDate == null || endDate == null) {
      history.push("?startDate=" + getFormattedDate(selectedStartDate) + "&endDate=" + getFormattedDate(selectedEndDate))
    }
    getPoolStat();
  }, [getPoolStat]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    if (startDate) {
      setSelectedStartDate(new Date(startDate))
    }
    if (endDate) {
      setSelectedEndDate(new Date(endDate))
    }
  }, [history]);


  const renderUserLink = (userId) => {
    return (<Link to={`/users/${userId}/edit`}>{userId}</Link>);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const generateLink = (excludedVarName) => {
    const params = new URLSearchParams(history.location.search)
    const endDate = params.get("endDate");
    const startDate = params.get("startDate");
    let link = "?";

    if (startDate !== null && excludedVarName !== "startDate") {
      link = link + "&startDate=" + startDate;
    }
    if (endDate !== null && excludedVarName !== "endDate") {
      link = link + "&endDate=" + endDate;
    }
    return link;
  }

  const handleStartDateChange = (date) => {
    const link = generateLink("startDate");
    setSelectedStartDate(date);
    history.push(link + "&startDate=" + getFormattedDate(date));
    getPoolStat()
  };

  const handleEndDateChange = (date) => {
    const link = generateLink("endDate");
    setSelectedEndDate(date);
    history.push(link + "&endDate=" + getFormattedDate(date));
    getPoolStat()
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/stat'}/>
        </Tabs>
      </Paper>
      <Paper square className="searchPanel">

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              id="date-picker-inline"
              style={{width: 140}}
              value={selectedStartDate}
              onChange={handleStartDateChange}
              autoOk={true}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <FormHelperText>Start date</FormHelperText>
          </FormControl>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              id="date-picker-inline"
              style={{width: 140}}
              value={selectedEndDate}
              onChange={handleEndDateChange}
              autoOk={true}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <FormHelperText>End date</FormHelperText>
          </FormControl>
        </MuiPickersUtilsProvider>
      </Paper>

      <div style={{display: "flex", marginBottom: 10}}>
        <Card className={'dashboardCard200'}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Total pool
            </Typography>
            {poolStat ?
              <>
                <Typography variant="h5" component="h2">
                  {toEur(poolStat.totalPool)}
                </Typography>
              </> : <div style={{textAlign: "center"}}><CircularProgress/></div>}
          </CardContent>
        </Card>
        <Card className={'dashboardCard200'}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Total PPQ
            </Typography>
            {poolStat ?
              <>
                <Typography variant="h5" component="h2">
                  {poolStat.totalPPQCount}
                </Typography>
              </> : <div style={{textAlign: "center"}}><CircularProgress/></div>}
          </CardContent>
        </Card>
      </div>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {poolStat && poolStat.poolBonusResult.map((el, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                <TableCell>{renderUserLink(el.userId)}</TableCell>
                <TableCell>{el.title}</TableCell>
                <TableCell>{el.ppqCount}</TableCell>
                <TableCell>{toEur(el.mainPool)}</TableCell>
                <TableCell>{el.crownAmbassador > 0 ? toEur(el.crownAmbassador) : ""}</TableCell>
                <TableCell>{el.grandAmbassador > 0 ? toEur(el.grandAmbassador) : ""}</TableCell>
                <TableCell>{el.ambassador > 0 ? toEur(el.ambassador) : ""}</TableCell>
                <TableCell>{el.crownDiamond > 0 ? toEur(el.crownDiamond) : ""}</TableCell>
                <TableCell>{el.blackDiamond > 0 ? toEur(el.blackDiamond) : ""}</TableCell>
                <TableCell>{el.blueDiamond > 0 ? toEur(el.blueDiamond) : ""}</TableCell>
                <TableCell>{el.diamond > 0 ? toEur(el.diamond) : ""}</TableCell>
                <TableCell>{el.emerald > 0 ? toEur(el.emerald) : ""}</TableCell>
                <TableCell>{el.ruby > 0 ? toEur(el.ruby) : ""}</TableCell>
                <TableCell>{el.sapphire > 0 ? toEur(el.sapphire) : ""}</TableCell>
                <TableCell>{el.platinum > 0 ? toEur(el.platinum) : ""}</TableCell>
                <TableCell>{el.gold > 0 ? toEur(el.gold) : ""}</TableCell>
                <TableCell>{el.total > 0 ? toEur(el.total) : ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";

export const OrdersPpcPacks = () => {
  const [value, setValue] = useState(2);
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [orders, setOrders] = useState();
  const history = useHistory();
  const [activeField, setActiveField] = useState('id')

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'userId', value: 'User', direction: 'asc', sorted: true},
    {name: 'totalAmount', value: 'Total price', direction: 'asc', sorted: true},
    {name: 'ticketsPrice', value: 'Ticket price', direction: 'asc', sorted: true},
    {name: 'ppcPrice', value: 'PPC price', direction: 'asc', sorted: true},
    {name: 'btcAmount', value: 'BTC amount', direction: 'asc', sorted: true},
    {name: 'paymentMethod', value: 'Method', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'weeks', value: 'Weeks', direction: 'asc', sorted: true},
    {name: 'invoiceNumber', value: 'Invoice', direction: 'asc', sorted: true},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
  ])

  const getOrders = useCallback(async () => {
    try {
      const _params = new URLSearchParams(history.location.search)
      const response = await client.getPpcOrders({
        page: currentPage,
        size: rowsPerPage,
        ...searchParamsToObject(_params),
      })
      setOrders(response.content);
      setTotal(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getOrders();
  }, [currentPage]);


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const renderUserLink = (userId) => {
    return (<Link to={`/users/${userId}/edit`}>{userId}</Link>);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const renderStatus = (order) => {
    return (
      <>
        {order.status === "NEW" ? "New" :
          order.status === "IN_PROGRESS" ? "Pending" :
            order.status === "PAID" ? "Paid" :
              order.status === "EXPIRED" ? "Expired" :
                order.status
        }
      </>
    );
  }

  const handleDownloadCSV = () => {
    try {
      {window.open('https://12022021.live/api/v1/stat/admin/orders/ppc/csv/QQ9EcccUumuHZjevd6ttERtq9JmyUQbL', '_blank')}
    } catch (e) {
      notification.warning(e.message || e.error);
    }
  }

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/orders'}/>
          <Tab label="Ticket orders" component={Link} to={'/orders/tickets'}/>
          <Tab label="PPC pack orders" component={Link} to={'/orders/ppc'}/>
          <Tab label="VCt orders" component={Link} to={'/orders/vct'}/>
          <Tab label="Invoices" component={Link} to={'/orders/invoices'}/>
        </Tabs>
      </Paper>
      <Paper square className="searchPanel">
        <Button
            variant="contained"
            size="medium"
            type="submit"
            onClick={handleDownloadCSV}
        >
          Download CSV
        </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders && orders.map((order, index) => (
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>{renderUserLink(order.userId)}</TableCell>
                  <TableCell>{order.amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{order.ticketPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{order.ppcPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{order.btcAmount}</TableCell>
                  <TableCell>{order.paymentMethod}</TableCell>
                  <TableCell>{renderStatus(order)}</TableCell>
                  <TableCell>{order.weeks}</TableCell>
                  <TableCell>{order.invoiceNumber}</TableCell>
                  <TableCell>{order.createdAt}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}
export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    CURRENT: 'users/current',
    ALL: 'search/users',
    GET_BY_ID: (id) => `search/users/${id}`,
    GET_LAST_LOGIN: (id) => `admin/users/${id}/lastLogin`,
    UPDATE: (id) => `admin/users/${id}`,
    CHANGE_JOIN_TYPE: (id) => `admin/users/${id}/joinTypeWithTickets`,
    REFUND: (id) => `admin/users/${id}/refund`,
    APPROVE_KYC: (id, type) => `users/kyc/approve/type/${type}/user/${id}`,
    DECLINE_KYC: (id, type) => `users/kyc/decline/type/${type}/user/${id}`,
    LOGIN_HISTORY: 'search/loginHistory',
  },
  WALLETS: {
    GET_BY_ID: (id) => `search/wallets/${id}`,
    ALL: 'search/wallets',
    TOPUP: 'wallets/admin/topUp',
    WITHDRAW: 'wallets/admin/withdraw',
    MOVE_VCT: 'wallets/admin/moveVCT',
    CSV: 'stat/admin/wallets/csv/ZwvcCQ3uCfPQdy23Z5WznT3277HWdDdFTMZf',
  },
  TRANSACTIONS: {
    ALL: 'search/transactions',
    GET_BY_ID: (id) => `search/transactions/${id}`,
    REVOKE_CASHBACK: (id) => `transactions/revoke/teamCashback/initUser/${id}`,
  },
  TRANSLATIONS: {
    ALL: 'search/translations',
    ADD_NEW_LANG: 'translations/addLang',
    ADD_NEW_KEY: 'translations/addKey',
    GET_AVAILABLE_LANGS: 'translations/langs',
    GET_BY_ID: (id) => `search/translations/${id}`,
    CHANGE_VALUE: (id) => `translations/change/value/${id}`,
  },
  PAYOUTS: {
    ALL: 'search/payouts',
    GET_BY_ID: (id) => `search/payouts/${id}`,
    CONFIRM_PAYOUT: (id) => `payouts/${id}/approve`,
    DECLINE_PAYOUT: (id) => `payouts/${id}/decline`,
  },
  TICKETS: {
    ALL: 'search/tickets',
    GET_BY_ID: (id) => `search/tickets/${id}`,
  },
  WINNERS: {
    ALL: 'search/winners',
    PAY_MANUAL: 'game/manualWin',
    GET_BY_ID: (id) => `search/winners/${id}`,
  },
  ORDERS: {
    ALL: 'search/orders',
    INVOICES: 'search/orders/invoices',
    ARUNA: 'search/orders/aruna',
    VCT: 'search/orders/vct',
    PPC: 'search/orders/ppc',
    TICKETS: 'search/orders/tickets',
    GET_BY_ID: (id) => `search/orders/${id}`,
    CONFIRM_INVOICE: (id) => `orders/invoice/${id}/approve`,
  },
  EXCHANGE: {
    ALL: 'search/exchange/orders',
    GET_BY_ID: (id) => `search/exchange/orders/${id}`,
  },
  VOUCHERS: {
    ALL: 'search/vouchers',
    GET_BY_ID: (id) => `search/vouchers/${id}`,
  },
  SECURE: {
    EXIST: 'secure/2fa/exist',
    CLEAR: 'secure/2fa/clear',
  },
  STAT: {
    DASHBOARD: 'stat/admin/dashboard',
    DASHBOARD_TICKETS: 'stat/admin/dashboard/tickets',
    DASHBOARD_WINS: 'stat/admin/dashboard/wins',
    DASHBOARD_USERS: 'stat/admin/dashboard/users',
    DASHBOARD_USERS_DIAGRAM: 'stat/admin/dashboard/users/diagram',
    GET_USER_ORGANISATION_STAT: (id) => `stat/admin/users/${id}/organisation/stat`,
  },
  ARUNA: {
    ALL: 'search/aruna',
    GET_BY_ID: (id) => `search/aruna/${id}`,
  },
  VCT: {
    ALL: 'search/vctToken',
    GET_BY_ID: (id) => `search/vctToken/${id}`,
  },
  VOTING: {
    ALL: 'search/voting',
  },
  RAFFLE: {
    GENERATE: 'raffle/generateRandom',
    ALL: 'search/raffle',
    GET_BY_ID: (id) => `search/raffle/${id}`,
  },
  POOL: {
    STAT: 'pool',
  },
};

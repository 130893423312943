import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab, Modal
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";
import {makeStyles} from "@material-ui/core/styles";

export const Invoices = () => {
  const [value, setValue] = useState(4);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [invoices, setInvoices] = useState();
  const history = useHistory();
  const [activeField, setActiveField] = useState('id')
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(null);

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'userId', value: 'User', direction: 'asc', sorted: true},
    {name: 'totalAmount', value: 'Total price', direction: 'asc', sorted: true},
    {name: 'ticketsPrice', value: 'Ticket price', direction: 'asc', sorted: true},
    {name: 'ppcPrice', value: 'PPC price', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'weeks', value: 'Weeks', direction: 'asc', sorted: true},
    {name: 'invoiceNumber', value: 'Invoice', direction: 'asc', sorted: true},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
    {name: 'actions', value: 'Actions', direction: 'asc', sorted: false},
  ])

  const handleOpenModal = (id) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setId(null)
    setOpenModal(false);
  };

  const getInvoices = useCallback(async () => {
    try {
      const _params = new URLSearchParams(history.location.search)
      const response = await client.getInvoices({
        page: currentPage,
        size: rowsPerPage,
        ...searchParamsToObject(_params),
      });
      setInvoices(response.content);
      setTotal(response.totalElements);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const renderActions = (invoice) => {
    return (
      <>
        {invoice.status === "IN_PROGRESS" ? <Button
            variant="text"
            size="small"
            type="button"
            style={{color: '#0070f0', marginRight: 10}}
            onClick={() => handleOpenModal(invoice.id)}
          >
            Confirm
          </Button>
          :
          ""
        }
      </>
    )
  }

  const renderUserLink = (userId) => {
    return (<Link to={`/users/${userId}/edit`}>{userId}</Link>);
  }

  const renderStatus = (invoice) => {
    return (
      <>
        {invoice.status === "NEW" ? "New" :
          invoice.status === "IN_PROGRESS" ? "Pending" :
            invoice.status === "PAID" ? "Paid" :
              invoice.status === "EXPIRED" ? "Expired" :
                invoice.status
        }
      </>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const approve = async () => {
    try {
      await client.confirmInvoice(id);
      notification.success("Invoice confirmed");
      getInvoices();
      handleCloseModal();
    } catch (e) {
      notification.warning(e.message || e.error);
    }
  }

  const modalBody = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Confirm invoice</h2>
      <p id="simple-modal-description">
        Order will be confirmed, tickets will be created, user status will be changed to member.
      </p>
      <div className={'buttonContainer'}>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={approve}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/orders'}/>
          <Tab label="Ticket orders" component={Link} to={'/orders/tickets'}/>
          <Tab label="PPC pack orders" component={Link} to={'/orders/ppc'}/>
          <Tab label="VCT orders" component={Link} to={'/orders/vct'}/>
          <Tab label="Invoices" component={Link} to={'/orders/invoices'}/>
        </Tabs>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices && invoices.map((invoice, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                <TableCell>{invoice.id}</TableCell>
                <TableCell>{renderUserLink(invoice.userId)}</TableCell>
                <TableCell>{invoice.amount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR'
                })}</TableCell>
                <TableCell>{invoice.ticketPrice.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR'
                })}</TableCell>
                <TableCell>{invoice.ppcPrice.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR'
                })}</TableCell>
                <TableCell>{renderStatus(invoice)}</TableCell>
                <TableCell>{invoice.weeks}</TableCell>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>{invoice.createdAt}</TableCell>
                <TableCell>{renderActions(invoice)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>


      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>

    </>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab, FormControl, Select, MenuItem, FormHelperText
} from "@material-ui/core";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import {Link, useHistory} from "react-router-dom";
import {getFormattedDate, searchParamsToObject} from "../../utils/tools";
import DateFnsUtils from "@date-io/date-fns";

export const Transactions = () => {
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [transactions, setTransactions] = useState();
  const [filterType, setFilterType] = useState(0)
  const [filterIo, setFilterIo] = useState(0)
  const [selectedStartDate, setSelectedStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const history = useHistory();
  const [activeField, setActiveField] = useState('id')

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'user', value: 'User', direction: 'asc', sorted: false},
    {name: 'initUser', value: 'Init user', direction: 'asc', sorted: false},
    {name: 'amountOriginal', value: 'Original', direction: 'asc', sorted: false},
    {name: 'amountCash', value: 'Cash', direction: 'asc', sorted: false},
    {name: 'amountTrading', value: 'Trading', direction: 'asc', sorted: false},
    {name: 'amountPpc', value: 'PPC', direction: 'asc', sorted: false},
    {name: 'type', value: 'Type', direction: 'asc', sorted: false},
    {name: 'ioType', value: 'I/O', direction: 'asc', sorted: false},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
  ])

  const getTransactions = useCallback(async () => {
    try {
      const _params = new URLSearchParams(history.location.search)
      const response = await client.getAllTransactions({
        page: currentPage,
        size: rowsPerPage,
        ...searchParamsToObject(_params),
      });

      setTransactions(response.content);
      setTotal(response.totalElements);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    if (startDate == null || endDate == null) {
      history.push("?startDate=" + getFormattedDate(selectedStartDate) + "&endDate=" + getFormattedDate(selectedEndDate))
    }
    getTransactions();
  }, [getTransactions]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const type = params.get('type');
    const io = params.get('io');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    if (type) {
      setFilterType(type);
    }
    if (io) {
      setFilterIo(io);
    }
    if (startDate) {
      setSelectedStartDate(new Date(startDate))
    }
    if (endDate) {
      setSelectedEndDate(new Date(endDate))
    }
  }, [history]);


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const renderUserLink = (userId) => {
    return (<Link to={`/users/${userId}/edit`}>{userId}</Link>);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleChangeFilterType = (e) => {
    const type = e.target.value;
    const link = generateLink("type");
    setFilterType(type);
    if (type !== 0) {
      history.push(link + "&type=" + type);
    } else {
      history.push(link);
    }
    getTransactions();
  }

  const handleChangeFilterIo = (e) => {
    const io = e.target.value;
    const link = generateLink("io");
    setFilterIo(io);
    if (io !== 0) {
      history.push(link + "&io=" + io);
    } else {
      history.push(link);
    }
    getTransactions();
  }

  const handleStartDateChange = (date) => {
    const link = generateLink("startDate");
    setSelectedStartDate(date);
    history.push(link + "&startDate=" + getFormattedDate(date));
    getTransactions();
  };

  const handleEndDateChange = (date) => {
    const link = generateLink("endDate");
    setSelectedEndDate(date);
    history.push(link + "&endDate=" + getFormattedDate(date));
    getTransactions();
  };

  const generateLink = (excludedVarName) => {
    const _params = new URLSearchParams(history.location.search)
    const type = _params.get("type");
    const io = _params.get("io");
    const endDate = _params.get("endDate");
    const startDate = _params.get("startDate");
    let link = "?";
    if (type !== null && excludedVarName !== "type") {
      link = link + "&type=" + type;
    }
    if (io !== null && excludedVarName !== "io") {
      link = link + "&io=" + io;
    }
    if (startDate !== null && excludedVarName !== "startDate") {
      link = link + "&startDate=" + startDate;
    }
    if (endDate !== null && excludedVarName !== "endDate") {
      link = link + "&endDate=" + endDate;
    }
    return link;
  }

  const handleDownloadCSV = () => {
    const _params = new URLSearchParams(history.location.search)
    const endDate = _params.get("endDate");
    const startDate = _params.get("startDate");
    try {
      {window.open('https://12022021.live/api/v1/stat/admin/transactions/' + filterType + "/" + filterIo + "/"
          + startDate + "/" + endDate
          + "/csv/8k9pRvAxd35W7ZgKtWCSsGacyBMsvyXF", '_blank')}
    } catch (e) {
      notification.warning(e.message || e.error);
    }
  }

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/transactions'}/>
        </Tabs>
      </Paper>
      <Paper square className="searchPanel">
        <FormControl>
          <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={filterType && filterType}
              onChange={handleChangeFilterType}
          >
            <MenuItem key={0} value={0}>All</MenuItem>
            <MenuItem key={1} value={"TEAM_CASHBACK"}>Team cashback</MenuItem>
            <MenuItem key={2} value={"TEAM_WINS"}>Team wins</MenuItem>
            <MenuItem key={3} value={"MAIN_DRAW_WINS"}>Main draw wins</MenuItem>
            <MenuItem key={4} value={"LUCKY_DRAW_WINS"}>Lucky draw wins</MenuItem>
            <MenuItem key={5} value={"POOL_BONUS"}>Pool bonus</MenuItem>
            <MenuItem key={6} value={"VOUCHER_DEPOSIT"}>Voucher deposit</MenuItem>
            <MenuItem key={7} value={"VOUCHER_CREATING"}>Voucher creating</MenuItem>
            <MenuItem key={8} value={"ADMIN_DEPOSIT"}>Admin deposit</MenuItem>
            <MenuItem key={9} value={"ADMIN_WITHDRAW"}>Admin withdraw</MenuItem>
            <MenuItem key={10} value={"PAYOUT"}>Payout</MenuItem>
            <MenuItem key={11} value={"PAYOUT_FEE"}>Payout fee</MenuItem>
            <MenuItem key={12} value={"TICKET_PURCHASE"}>Ticket purchase</MenuItem>
            <MenuItem key={13} value={"PPC_PURCHASE"}>PPC purchase</MenuItem>
            <MenuItem key={14} value={"LOST_CASHBACK"}>Lost cashback</MenuItem>
            <MenuItem key={15} value={"VOUCHER_REFUND"}>Voucher refund</MenuItem>
            <MenuItem key={16} value={"CANCELED"}>Canceled</MenuItem>
            <MenuItem key={17} value={"PPC_SELLER_FEE"}>PPC seller fee</MenuItem>
            <MenuItem key={18} value={"FT_REWARD"}>FT reward</MenuItem>
            <MenuItem key={19} value={"PERCENTAGE_POOL_BONUS"}>Percentage pool bonus</MenuItem>
            <MenuItem key={20} value={"TOKEN_PURCHASE"}>Token purchase</MenuItem>
          </Select>
          <FormHelperText>Type</FormHelperText>
        </FormControl>
        <FormControl>
          <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={filterIo && filterIo}
              onChange={handleChangeFilterIo}
          >
            <MenuItem key={0} value={0}>All</MenuItem>
            <MenuItem key={1} value={"INPUT"}>Input</MenuItem>
            <MenuItem key={2} value={"OUTPUT"}>Output</MenuItem>
          </Select>
          <FormHelperText>I/O</FormHelperText>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                id="date-picker-inline"
                style={{width: 140}}
                value={selectedStartDate}
                onChange={handleStartDateChange}
                autoOk={true}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
            />
            <FormHelperText>Start date</FormHelperText>
          </FormControl>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl>
          <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              id="date-picker-inline"
              style={{width: 140}}
              value={selectedEndDate}
              onChange={handleEndDateChange}
              autoOk={true}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
          />
            <FormHelperText>End date</FormHelperText>
          </FormControl>
        </MuiPickersUtilsProvider>
        <Button
            variant="contained"
            size="medium"
            type="submit"
            style={{marginLeft: 30}}
            onClick={handleDownloadCSV}
        >
          Download CSV
        </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions && transactions.map((transaction, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                <TableCell>{transaction.id}</TableCell>
                <TableCell>{renderUserLink(transaction.userId)}</TableCell>
                <TableCell>{renderUserLink(transaction.initUserId)}</TableCell>
                <TableCell>{transaction.amountOriginal.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 4
                })}</TableCell>
                <TableCell>{transaction.amountCash.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 4
                })}</TableCell>
                <TableCell>{transaction.amountTrading.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 4
                })}</TableCell>
                <TableCell>{transaction.amountPpc}</TableCell>
                <TableCell>{transaction.type}</TableCell>
                <TableCell>{transaction.ioType}</TableCell>
                <TableCell>{transaction.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}
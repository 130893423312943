import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import {useHistory} from 'react-router-dom';
import {jwt} from "../../services/jwt";
import {logout, setAuth, setUser} from "../../store/actions/actions";
import {client} from "../../services/client";
import {useDispatch} from "react-redux";
import * as Yup from 'yup';
import './Auth.css';
import {Button, Card, CardContent, Input, TextField} from "@material-ui/core";
import {notification} from "../../components/Notification";

export const Auth = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({email, password}) => {
      setIsSubmitting(true);
      try {
        const response = await client.auth2fa({email, password});
        if (response.access_token !== '') {
          jwt.setJwtData(response.access_token, response.refresh_token);
          const user = await client.getCurrentUser();

          if (user.role === 'ROLE_ADMIN' || user.role === "ROLE_MODERATOR") {
            dispatch(setUser(user));
            dispatch(setAuth(true));
            history.push('/dashboard');
          } else if (user.role === 'ROLE_TRANSLATOR') {
            dispatch(setUser(user));
            dispatch(setAuth(true));
            history.push('/translations');
          } else {
            notification.warning("Access denied");
          }
        } else {

        }
      } catch (e) {
        notification.warning(e.message || e.error);
        setIsSubmitting(false);
      }
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email is required'),
      password: Yup.string()
        .min(5, 'At least 5 chars')
        .required('Password is required'),
    }),
  });
  const {values, errors, touched, dirty, handleChange, handleBlur, handleSubmit} = formik;
  const hasErrorsOrNotDirty = !!Object.keys(errors).length || !dirty;

  return (
    <>
      <Card className={'authContainer'}>
        <CardContent>
          <p style={{marginBottom: 20}} className='guestTitle'>Login</p>
          <form onSubmit={handleSubmit} style={{width: "100%"}}>
            <div style={{marginBottom: 20}}>
              <TextField
                id="email"
                name="email"
                type="text"
                label={"Email"}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                style={{width: "100%"}}
              />
            </div>
            <div style={{marginBottom: 40}}>
              <TextField
                id="password"
                name="password"
                label={"Password"}
                type={'password'}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{width: "100%"}}
              />
            </div>
            <div>
              <Button
                color='primary'
                variant="contained"
                type="submit"
                style={{margin: "0 auto"}}
                // disabled={isSubmitting || hasErrorsOrNotDirty}
              >
                {isSubmitting ? 'Processing' : 'Log in'
                }
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
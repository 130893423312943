import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab
} from "@material-ui/core";
import {Link, useHistory, useParams} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";

export const WinnersEdit = () => {
  const [value, setValue] = useState(1);
  const [winner, setWinner] = useState();
  const history = useHistory();
  const { id } = useParams()

  const getWinner = useCallback(async () => {
    try {
      console.log(id)
      const response = await client.getWinnerById(id);
      setWinner(response);
    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, []);

  useEffect(() => {
    getWinner();
  }, [getWinner]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/winners'}/>
          <Tab label="Edit" component={Link} to={'/winners/' + id + '/edit'}/>
        </Tabs>
      </Paper>
    </>
  )
}
import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer, Button, Tabs, Tab
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";

export const Vouchers = () => {
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [vouchers, setVouchers] = useState();
  const history = useHistory();
  const [activeField, setActiveField] = useState('id')

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'userId', value: 'User', direction: 'asc', sorted: false},
    {name: 'activatedBy', value: 'Activated by', direction: 'asc', sorted: false},
    {name: 'orderId', value: 'Order', direction: 'asc', sorted: false},
    {name: 'code', value: 'Code', direction: 'asc', sorted: false},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: false},
    {name: 'status', value: 'Status', direction: 'asc', sorted: false},
    {name: 'type', value: 'Type', direction: 'asc', sorted: false},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
  ])

  const getVouchers = useCallback(async () => {
    try {
      const _params = new URLSearchParams(history.location.search)
      const response = await client.getAllVouchers({
        page: currentPage,
        size: rowsPerPage,
        ...searchParamsToObject(_params),
      })
      setTotal(response.totalElements)
      setVouchers(response.content);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getVouchers();
  }, [getVouchers]);


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const renderUserLink = (userId) => {
    return (<Link to={`/users/${userId}/edit`}>{userId}</Link>);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };


  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/vouchers'}/>
        </Tabs>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {vouchers && vouchers.map((voucher, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                <TableCell>{voucher.id}</TableCell>
                <TableCell>{renderUserLink(voucher.userId)}</TableCell>
                <TableCell>{renderUserLink(voucher.activatedBy)}</TableCell>
                <TableCell>{voucher.orderId}</TableCell>
                <TableCell>{voucher.code}</TableCell>
                <TableCell>{voucher.amount}</TableCell>
                <TableCell>{voucher.status}</TableCell>
                <TableCell>{voucher.type}</TableCell>
                <TableCell>{voucher.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}
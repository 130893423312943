import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TableContainer, Button, Tabs, Tab, Modal, Tooltip
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {searchParamsToObject} from "../../utils/tools";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import {Cancel, ThumbUp} from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Cancel';

export const Payouts = () => {
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [payouts, setPayouts] = useState();
  const history = useHistory();
  const [activeField, setActiveField] = useState('id')
  const [openModal, setOpenModal] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [id, setId] = useState(null);
  const [pageAndSizeState, setPageAndSizeState] = useState({
    page: 0,
    size: 10,
    total: 1,
  });
  const [headerFields, setHeaderFields] = React.useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'userId', value: 'User', direction: 'asc', sorted: true},
    {name: 'requestedAmount', value: 'Requested', direction: 'asc', sorted: false},
    {name: 'payableAmount', value: 'Payable', direction: 'asc', sorted: false},
    {name: 'fee', value: 'Fee', direction: 'asc', sorted: false},
    {name: 'cryptoAmount', value: 'Crypto amount', direction: 'asc', sorted: false},
    {name: 'currency', value: 'Currency', direction: 'asc', sorted: false},
    {name: 'cryptoRate', value: 'Crypto rate', direction: 'asc', sorted: false},
    {name: 'cryptoAddress', value: 'Crypto address', direction: 'asc', sorted: false},
    {name: 'status', value: 'Status', direction: 'asc', sorted: false},
    {name: 'type', value: 'Type', direction: 'asc', sorted: false},
    {name: 'createdAt', value: 'Created', direction: 'asc', sorted: false},
    {name: 'Actions', value: 'Actions', direction: 'asc', sorted: false},
  ])

  const handleOpenModal = (id) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setId(null)
    setOpenModal(false);
  };

  const handleOpenDeclineModal = (id) => {
    setId(id);
    setOpenDeclineModal(true);
  };

  const handleCloseDeclineModal = () => {
    setId(null)
    setOpenDeclineModal(false);
  };

  const renderUserLink = (userId) => {
    return (<Link to={`/users/${userId}/edit`}>{userId}</Link>);
  }

  const renderActions = (payout) => {
    return (
      <>
        {payout.status === "Pending" ?
            (
                <Tooltip title="Confirm" aria-label="confirm">
                  <ThumbUp className="actionIcon green" onClick={() => handleOpenModal(payout.id)} />
                </Tooltip>
            )
          :
          ""
        }
        {payout.status !== "Completed" && payout.status !== "Failed" ?
            (
                <Tooltip title="Decline" aria-label="decline">
                  <Cancel className="actionIcon red" onClick={() => handleOpenDeclineModal(payout.id)} />
                </Tooltip>
            )
            :
            ""
        }
      </>
    )
  }

  const approve = async () => {
    try {
      await client.confirmPayout(id);
      notification.success("Payout confirmed");
      getPayouts();
      handleCloseModal();
    } catch (e) {
      notification.warning(e.message || e.error);
    }
  }

  const modalBody = (
    <div className={'modal'}>
      <h2 id="simple-modal-title">Confirm payout</h2>
      <p id="simple-modal-description">
        Payout will be confirmed, transaction in the system will be created.
      </p>
      <div className={'buttonContainer'}>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={approve}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );


  const decline = async () => {
    try {
      await client.declinePayout(id);
      notification.success("Payout declined");
      getPayouts();
      handleCloseDeclineModal();
    } catch (e) {
      notification.warning(e.message || e.error);
    }
  }

  const modalDeclineBody = (
      <div className={'modal'}>
        <h2 id="simple-modal-title">Decline payout</h2>
        <p id="simple-modal-description">
          Payout will be declined, amount will be transferred from hold to cash wallet.
        </p>
        <div className={'buttonContainer'}>
          <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={decline}
          >
            OK
          </Button>
          <Button
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleCloseDeclineModal}
          >
            Cancel
          </Button>
        </div>
      </div>
  );

  const getPayouts = useCallback(async () => {
    try {
      const _params = new URLSearchParams(history.location.search)
      const response = await client.getAllPayouts({
        page: currentPage,
        size: rowsPerPage,
        ...searchParamsToObject(_params),
      })
      setTotal(response.totalElements);
      setPayouts(response.content);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getPayouts();
  }, [getPayouts]);


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/payouts'}/>
        </Tabs>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {payouts && payouts.map((payout, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                <TableCell>{payout.id}</TableCell>
                <TableCell>{renderUserLink(payout.userId)}</TableCell>
                <TableCell>{payout.requestedAmount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR'
                })}</TableCell>
                <TableCell>{payout.payableAmount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR'
                })}</TableCell>
                <TableCell>{payout.fee.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'EUR'
                })}</TableCell>
                <TableCell>{payout.cryptoAmount}</TableCell>
                <TableCell>{payout.currency}</TableCell>
                <TableCell>{payout.cryptoRate}</TableCell>
                <TableCell>{payout.cryptoAddress}</TableCell>
                <TableCell>{payout.status}</TableCell>
                <TableCell>{payout.type}</TableCell>
                <TableCell>{payout.createdAt}</TableCell>
                <TableCell>{renderActions(payout)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>

      <Modal
          open={openDeclineModal}
          onClose={handleCloseDeclineModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
      >
        {modalDeclineBody}
      </Modal>

    </>
  )
}
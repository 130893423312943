import React, {useCallback, useEffect} from 'react';
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import {DashboardLayout} from "./components/Layout/DashboardLayout/DashboardLayout";
import {Auth} from "./pages/auth/Auth";
import {GuestLayout} from "./components/Layout/GuestLayout/GuestLayout";
import {Users} from "./pages/users/Users";
import {Dashboard} from "./pages/dashboard/Dashboard";
import {UsersEdit} from "./pages/users/UsersEdit";
import {Transactions} from "./pages/transactions/Transactions";
import {TransactionsEdit} from "./pages/transactions/TransactionsEdit";
import {Payouts} from "./pages/payouts/Payouts";
import {PayoutsEdit} from "./pages/payouts/PayoutsEdit";
import {Tickets} from "./pages/tickets/Tickets";
import {TicketsEdit} from "./pages/tickets/TicketsEdit";
import {Winners} from "./pages/winners/Winners";
import {WinnersEdit} from "./pages/winners/WinnersEdit";
import {Orders} from "./pages/orders/Orders";
import {OrdersEdit} from "./pages/orders/OrdersEdit";
import {Invoices} from "./pages/orders/Invoices";
import {ExchangeOrders} from "./pages/exchange/ExchangeOrders";
import {ExchangeOrdersEdit} from "./pages/exchange/ExchangeOrdersEdit";
import {Vouchers} from "./pages/vouchers/Vouchers";
import {VouchersEdit} from "./pages/vouchers/VouchersEdit";
import {Translations} from "./pages/translations/Translations";
import {TranslationsNewLang} from "./pages/translations/TranslationsNewLang";
import {TranslationsAddKey} from "./pages/translations/TranslationsAddKey";
import {Aruna} from "./pages/aruna/Aruna";
import {Wallets} from "./pages/wallets/Wallets";
import {WalletsEdit} from "./pages/wallets/WalletsEdit";
import {OrdersAruna} from "./pages/orders/OrdersAruna";
import {OrdersPpcPacks} from "./pages/orders/OrdersPpcPacks";
import {OrdersTickets} from "./pages/orders/OrdersTickets";
import {VctTokens} from "./pages/vct/VctTokens";
import {OrdersVct} from "./pages/orders/OrdersVct";
import {Diagrams} from "./pages/dashboard/Diagrams";
import {Raffle} from "./pages/raffle/Raffle";
import {Voting} from "./pages/voting/Voting";
import {Pool} from "./pages/pool/Pool";

export const useRoutes = (isAuth, userStatus) => {
  if (isAuth) {
    return (
      <DashboardLayout>
        <Route path="/" exact={true}>
          <Dashboard/>
        </Route>
        <Route path="/dashboard" exact={true}>
          <Dashboard/>
        </Route>
        <Route path="/dashboard/diagrams" exact={true}>
          <Diagrams/>
        </Route>

        <Route path="/users" exact={true}>
          <Users/>
        </Route>
        <Route path="/users/:id/edit" exact={true}>
          <UsersEdit/>
        </Route>

        <Route path="/wallets" exact={true}>
          <Wallets/>
        </Route>
        <Route path="/wallets/:id/edit" exact={true}>
          <WalletsEdit/>
        </Route>

        <Route path="/transactions" exact={true}>
          <Transactions/>
        </Route>
        <Route path="/transactions/:id/edit" exact={true}>
          <TransactionsEdit/>
        </Route>

        <Route path="/payouts" exact={true}>
          <Payouts/>
        </Route>
        <Route path="/payouts/:id/edit" exact={true}>
          <PayoutsEdit/>
        </Route>

        <Route path="/tickets" exact={true}>
          <Tickets/>
        </Route>
        <Route path="/tickets/:id/edit" exact={true}>
          <TicketsEdit/>
        </Route>

        <Route path="/winners" exact={true}>
          <Winners/>
        </Route>
        <Route path="/winners/:id/edit" exact={true}>
          <WinnersEdit/>
        </Route>

        <Route path="/orders" exact={true}>
          <Orders/>
        </Route>
        <Route path="/orders/:id/edit" exact={true}>
          <OrdersEdit/>
        </Route>
        <Route path="/orders/invoices" exact={true}>
          <Invoices/>
        </Route>
        <Route path="/orders/aruna" exact={true}>
          <OrdersAruna/>
        </Route>
        <Route path="/orders/vct" exact={true}>
          <OrdersVct/>
        </Route>
        <Route path="/orders/ppc" exact={true}>
          <OrdersPpcPacks/>
        </Route>
        <Route path="/orders/tickets" exact={true}>
          <OrdersTickets/>
        </Route>

        <Route path="/exchangeOrders" exact={true}>
          <ExchangeOrders/>
        </Route>
        <Route path="/exchangeOrders/:id/edit" exact={true}>
          <ExchangeOrdersEdit/>
        </Route>

        <Route path="/vouchers" exact={true}>
          <Vouchers/>
        </Route>
        <Route path="/vouchers/:id/edit" exact={true}>
          <VouchersEdit/>
        </Route>

        <Route path="/raffle" exact={true}>
          <Raffle/>
        </Route>

        <Route path="/pool" exact={true}>
          <Pool/>
        </Route>

        <Route path="/voting" exact={true}>
          <Voting/>
        </Route>

        <Route path="/vctTokens" exact={true}>
          <VctTokens/>
        </Route>

        <Route path="/translations" exact={true}>
          <Translations/>
        </Route>
        <Route path="/translations/:id/edit" exact={true}>
          <TransactionsEdit/>
        </Route>
        <Route path="/translations/addNewLang" exact={true}>
          <TranslationsNewLang/>
        </Route>
        <Route path="/translations/addNewRecord" exact={true}>
          <TranslationsAddKey/>
        </Route>
      </DashboardLayout>
    )
  }
  return (
    <GuestLayout>
      <Switch>
        <Route path="/login">
          <Auth/>
        </Route>
        <Redirect to={'/login'}/>
      </Switch>
    </GuestLayout>
  )
}
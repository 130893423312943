import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {
  Paper, Tabs, Tab, TextField, Button, TextareaAutosize
} from "@material-ui/core";
import {Link, useHistory, useParams} from "react-router-dom";

export const TranslationsAddKey = () => {
  const [value, setValue] = useState(2);
  const [translation, setTranslation] = useState();
  const history = useHistory();
  const {id} = useParams()


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addNewKey = async (e) => {
    try {
      e.preventDefault();
      await client.addTranslationKey({
        place: e.target.place.value,
        key: e.target.key.value,
        value: e.target.value.value
      });
      notification.success("New record added");
      history.push("/translations")
    } catch (e) {
      notification.warning(e.message || e.error);
    }
  }

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="All" component={Link} to={'/translations'}/>
          <Tab label="Add new lang" component={Link} to={'/translations/addNewLang'}/>
          <Tab label="Add new record" component={Link} to={'/translations/addNewRecord'}/>
        </Tabs>
      </Paper>

      <Paper square className="simpleFormPaper">
        <form onSubmit={addNewKey}>
          <TextField
            id="place"
            name="place"
            type="text"
            label="Place"
            placeholder="Place"
            autoComplete="off"
            fullWidth={true}
            required={true}
          />
          <TextField
            id="key"
            name="key"
            type="text"
            label="Key"
            placeholder="Key"
            autoComplete="off"
            fullWidth={true}
            required={true}
          />
          <div style={{marginTop: 10}}>
            <TextareaAutosize
              id="value"
              name="value"
              type="text"
              label="Value"
              aria-label="value"
              minRows={3}
              placeholder="Value"
              required={true}
            />
          </div>
          <div className="buttonContainer">
            <Button
              variant="contained"
              size="medium"
              color="primary"
              type="submit"
            >
              Add
            </Button>
          </div>
        </form>
      </Paper>
    </>
  )
}